export default function zhCNText(){
  return (
    <ol className="decimal">
      <li>概述</li>
      <br/>
      <p><b>卡塔尔航空集团Q.C.S.C.</b>（“<b>卡塔尔航空</b>”<b>或</b>“<b>我们</b>”）希望您在使用本网站（“<b>微网站</b>”）时感到安全，因此，我们承诺在您使用本网站时保护您的个人数据和隐私权。</p>
      <p>本《Flyqatar.com隐私声明》（“<b>下称“隐私声明</b>”) 与我们的 <a target="_blank" rel="noreferrer" href="/termsandconditions?selLang=zh-cn">《条款与条件》</a> 和 <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/zh-cn/legal/cookie-policy.html">《Cookie政策》</a> 共同解释了卡塔尔航空及其服务提供商现在或随后将如何处理个人数据。我们所指的“<b>个人数据</b>”是指与已识别或可识别的自然人关联的任何信息。</p>
      <p>请仔细阅读以下内容，以了解我们如何处理您在本微网站上的个人数据。<br/> 如果您代表他人披露个人数据，您承诺并将确保向卡塔尔航空提供的个人数据所属的个人已在您披露相关个人数据之前授权该披露，并已获得本隐私声明的副本。</p>
      <p>如果我们更改本隐私声明，我们将以在本微网站上发布更新版本的形式来通知您。在适用法律允许的前提下，本隐私声明的英文版本将优先于任何其他语言翻译版本。
      </p>
      <li>本隐私声明适用于什么情况？</li>
      <br/>
      <p>本隐私声明适用于对本微网站上的个人数据的处理。</p>
      <li>个人数据的处理由谁负责？</li>
      <br/>
      <p>卡塔尔航空（注册地址为“<b>Qatar Airways Tower 1, PO Box 22550, Doha, State of Qatar（卡塔尔国）</b>”）作为数据控制者负责处理本隐私声明中所述的个人数据。</p>
      <li>我们如何收集和接收个人数据</li>
      <br/>
      <p>我们和我们的服务提供商可能会以各种方式直接向您收集和接收个人数据，包括：</p>
      <p>
        <u>通过微网站</u>：我们可通过微网站收集个人数据，例如在您使用微网站上提供的服务时。<br/>
        <u>线下渠道</u>在某些特定场所，如购物中心，我们可能会根据您是否有兴趣和意愿使用微网站上所提供的服务来收集您的个人数据，例如在您允许我们的官方设备拍摄您的照片并上传至微网站，以获取包含您个人形象的定制视频时。<br/>
        我们的微网站可能包含可将您导向第三方网站的链接。请注意，我们无法控制相关第三方网站的数据控制者如何处理您的个人数据，且对其运营方式或处理您个人数据的方式不承担任何义务或责任。您应理解这一点，并查看相关第三方网站的隐私政策和使用条款。
      </p>
      <li>收集的个人数据有哪些</li>
      <br/>
      <p>
        ”<b>个人数据</b>” 是指可识别您的个人身份或与可识别的个人有关的信息，包括：<br/>
        <ol className="roman">
          <li>图像/照片；和业务</li>
          <li>电子邮箱。</li>
        </ol>
      </p>
      <p>为了让您能够在我们的《条款与条件》所述的条件下创建影片，我们可能需要收集和处理以上类型的个人数据。如果您不提供我们要求的信息，您可能无法参与在我们的微网站上创建影片。</p>
      <li>收集个人数据的目的是什么？</li>
      <br/>
      <p>我们和我们的服务提供商可根据您的同意使用个人数据。</p>
      <p>您的图像或照片可根据您的选择用于制作影片/视频，并让您能够参与和使用本微网站提供的服务。在线下地点，如果您表示同意，您的图像或照片及相关影片/视频可能会在同一地点的数字广告牌上暂时性显示。</p>
      <p>在您使用我们的微网站后，我们会使用您的电子邮箱向您发送所生成的影片/视频链接。我们不会将您的数据用于任何其他目的。如果您希望通过电子邮箱收到定制影片/视频，您可选择提供您的电子邮箱地址。</p>
      <p>在您订阅我们的电子快讯时，我们会使用您的电子邮箱发送包含最新信息的电子快讯。您可以随时通过每期电子快讯中提供的取消订阅链接退出接收电子快讯。</p>
      <li>自动化决策</li>
      <br/>
      <p>我们使用人工智能 (“<b>AI</b>”) 来生成您的定制影片/视频。</p>
      <p>换脸技术利用先进的机器学习算法和人工智能来识别图像或视频中的人脸，并将其替换。这一过程通常包括人脸检测、特征提取和人脸合成。算法首先检测内容中的人脸，然后分析关键的面部特征，最后将替换后的人脸与原始图像或视频融合，同时匹配肤色、光照和表情，以确保无缝过渡。</p>
      <li>COOKIE</li>
      <br/>
      <p>Cookie是一种小型信息文件，用于保存和检索有关您访问本微网站的信息，例如，您如何进入我们的网站、如何在网站内导航以及对什么信息感兴趣。请阅读我们的 <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/zh-cn/legal/cookie-policy.html">《Cookie政策》</a>以进一步了解我们如何使用Cookie。</p>
      <li>数据传输</li>
      <br/>
      <p><u>卡塔尔航空集团内部</u></p>
      <p>卡塔尔航空的员工仅在为实现相关目的和履行工作职责所必需的情况下，才被授权访问个人数据。</p>
      <p>我们是总部位于卡塔尔国多哈的全球性组织，我们收集的个人数据可能会出于业务或管理目的在卡塔尔航空集团内部跨国传输至卡塔尔航空公司所驻的国家。这些国家的数据保护法律的全面性可能低于欧洲经济区 (“<b>EEA</b>”). 的法律。在必要时，卡塔尔航空会确保采取适当的保障措施，以符合适用数据保护和隐私法律对个人数据跨国传输的规定。</p>
      <p><u>卡塔尔航空集团以外</u><br/>在下列情况下，我们可能需要披露您的某些个人数据：</p>
      <ol className="alpha">
        <li>提供给包括服务提供商在内的第三方，以便其为我们或与我们为您提供的服务相关的指定方（包括我们的代理）提供服务或履行职能，包括但不限于支持服务提供商、技术提供商、专业顾问、法律顾问、审计师等；</li>
        <li>提供给与我们全部或部分业务或资产的融资、证券化、保险、重组、转让或其他处置有关的第三方，或与索赔辩护有关的第三方；以及</li>
        <li>提供给权力部门，包括执法官员、法院和政府及监管机构：(a)如果我们认为任何适用法律、法规或法律程序要求披露（例如回应传票或司法命令）；或（b）为我们的权利或第三方的权利或安全（包括对法律索赔进行辩护）提供保护和辩护；</li>
      </ol>
      <p>在向第三方披露个人数据时，我们将根据适用法律采取必要的合同、技术和组织措施来妥善保护相关个人数据。<br/>
        上述披露可能涉及个人数据的跨国传输，传输的起始和目的地国家可能位于您居住国以外，且相关国家的数据保护法律的全面性可能低于欧洲经济区（EEA）。在必要时，卡塔尔航空将确保采取适当的保障措施，以符合适用数据保护和隐私法律对个人数据跨国传输的规定。
      </p>
      <li>我们如何保障您的数据安全？</li>
      <br/>
      <p>卡塔尔航空致力于确保个人数据的安全性，并已采取措施保护个人数据。为防止个人数据遭到未经授权的访问或披露，我们已制定物理、技术和管理方面的保障措施，以保护个人数据不受意外或非法破坏、意外丢失、损坏、篡改、未经授权的披露或访问，以及其他所有形式的非法处理。<br/>
        丢失、损坏、篡改、未经授权的披露或访问，以及其他所有形式的非法处理。
        尽管我们已采取合理适当的个人数据保护措施，我们无法对通过互联网或类似连接传输的个人数据的安全性作出保证。如果您有理由认为您与我们之间的互动不再安全，请立即按照下文“<b>如何联系我们</b>”一节的说明通知我们。</p>
      <li>未成年人使用微网站</li>
      <br/>
      <p>本微网站非面向未满十八（18）周岁的人士，我们不会有意收集十八周岁以下人士的个人数据。</p>
      <li>数据的保留</li>
      <br/>
      <p>我们保留数据时长依照实现本隐私声明中所述的收集数据的特定目的、卡塔尔航空的法律或商业目的的需要和我们的数据保留政策，或根据相关法律规定。在删除个人数据时，我们将根据适用法律采取商业上合理且技术上可行的措施，使个人数据不可恢复或不可复制。</p>
      <li>未成年人使用微网站</li>
      <br/>
      <p>本微网站非面向未满十八（18）周岁的人士，我们不会有意收集十八周岁以下人士的个人数据。</p>
      <li>您的权利</li>
      <br/>
      <p>您可以随时通过qatarairways.com.qa网站“联系我们”页面上的“<a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/zh-cn/subject-access-request-form.html">数据主体访问请求</a>”门户发送请求，对卡塔尔航空处理的个人数据行使访问、更正、删除、限制处理、数据可携性、反对的权利，或是与自动化个人决策（包括个人资料分析）相关的权利。根据您居住的国家/地区，您可能享有其他隐私权，包括《加州消费者隐私法》(“<b>CCPA</b>”)规定的权利。</p>
      <p>在处理您的请求之前，我们将验证您的身份，以确保将请求的信息提供给相关的数据主体。如果您已授权代理人代表您提出请求，我们会要求您或您的授权代理人向我们提供该等授权的书面许可。</p>
      <p>我们不会对您的请求收取费用，除非该请求被视为明显无根据或过度。如果您提出要求，我们可以根据《中华人民共和国个人信息保护法》向您提供第三方个人信息处理者的名单。<br/>
        如果您认为我们未遵守适用的隐私规则，您有权向数据保护主管机构提出投诉。
      </p>
      <p>如果您不想再收到我们的电子快讯，您可以随时通过单击任何电子快讯邮件中的“取消订阅”链接来取消订阅。此外，您也可以通过电子邮件、致电我们的客户服务中心或邮寄方式向我们提出退订请求。邮寄地址：</p>
      <p>
        卡塔尔航空集团 Q.C.S.C<br/>
        Qatar Airways Tower 1,<br/>
        PO Box 22550,<br/>
        Doha, State of Qatar.<br/>
        收件人：卡塔尔航空直销团队 – 电子商务
      </p>
      <p>在您通过电子邮件、电话或邮寄方式提出退订请求后，最多需要30个工作日来处理请求、更新我们的系统和从我们的邮件列表中删除您的电子邮件地址。</p>
      <li>如何联系我们或投诉</li>
      <br/>
      <p>如果您对您的个人数据的处理或本隐私声明有任何疑问或顾虑，请联系：</p>
      <p>电子邮箱：<a target="_blank" rel="noreferrer" href="mailto:digitalbrand@qatarairways.com.qa">digitalbrand@qatarairways.com.qa</a></p>
      <p>
        Qatar Airways Group Q.C.S.C.<br/>
        Qatar Airways Tower 1,<br/>
        PO Box 22550,<br/>
        Doha, State of Qatar.<br/>
        收件人：市场营销部
      </p>
      <p>您也可以<a target="_blank" rel="noreferrer" href="mailto:dpo@qatarairways.com.qa">在这里</a>发送电子邮件联系我们的数据保护官。请注意，您也有权向主管监督机构投诉。(中文为译本，请以英文版为准。)</p>
      <br/>
    </ol>
  )
}
