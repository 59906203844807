import React from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface Props {
    type: 'button' | 'submit';
    variant?: 'contained' | 'outlined';
    color?: 'burgundy' | 'white';
    className?: string;
    disabled?: boolean;
    onClick?: any;
    children: React.ReactNode;
    title?: string;
    id?: string;
}

const Button = ({
    type,
    variant,
    color,
    disabled,
    className,
    onClick,
    children,
    title,
    id
}: Props) => {
    return (
        <button
            type={type}
            className={clsx(styles.button, className, {
                [styles.outlined]: variant === 'outlined',
                [styles.white]: color === 'white',
            })}
            disabled={disabled}
            onClick={onClick}
            title={title}
            id={id}
        >
            {children}
        </button>
    );
};
export default Button;
