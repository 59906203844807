import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import {
    EmailIcon,
    FacebookIcon,
    FacebookShareButton,
    XIcon,
    TwitterShareButton,
    LinkedinIcon,
    WhatsappIcon,
    WhatsappShareButton,
    WeiboIcon,
    WeiboShareButton,
} from 'react-share';
import styles from './styles.module.scss';
import Button from '../ui/Button';
import { useTranslation } from 'react-i18next';
import copyIcon from '../../assets/icons/copy.svg';
import checkIcon from '../../assets/icons/check.svg';
import weibo from '../../assets/icons/weibo.svg';
import clsx from 'clsx';
import { useMediaQuery } from '@mui/material';
import triggerDataLayer from '../../helpers/dataLayerTrigger';

interface Props {
    shareUrl: string;
    title: string;
    open: boolean;
    handleClose: () => void;
    sceneNumber: number;
}

const ResultShare = ({
    shareUrl,
    title,
    open,
    handleClose,
    sceneNumber,
}: Props) => {
    const { t, i18n: { language } } = useTranslation();

    const [isCopied, setIsCopied] = useState<boolean>(false);
    const isTablet = useMediaQuery('(max-width:768px)');

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`${shareUrl}`);
        setIsCopied(true);
        onShareHandler('copy_link')();
        const timeout = setTimeout(() => {
            setIsCopied(false);
        }, 2000);
        return () => clearTimeout(timeout);
    };

    const onShareHandler =
        (
            shareOption:
                | 'facebook'
                | 'twitter'
                | 'linkedin'
                | 'whatsapp'
                | 'email'
                | 'copy_link'
                | 'weibo'
        ) =>
        () => {
            triggerDataLayer('video_share', {
                scene_number: `scene ${sceneNumber + 1}`,
                scene_description: title,
                share_option: shareOption,
            });
        };

    return (
        <Dialog onClose={handleClose} open={open} className={styles.dialog}>
            <div className={styles.shareBox}>
                <div className={styles.closeModalButton} onClick={handleClose}>
                    &#x2715;
                </div>
                <div className={styles.shareTitle}>
                    {t('share_modal_title')}
                </div>
                <div className={styles.share}>
                    <FacebookShareButton
                        id="facebook-share-button"
                        url={shareUrl}
                        className="Demo__some-network__share-button"
                        onClick={onShareHandler('facebook')}
                    >
                        <FacebookIcon size={isTablet ? 32 : 60} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                        id="twitter-share-button"
                        url={shareUrl}
                        title={t('text_to_share')}
                        className="Demo__some-network__share-button"
                        onClick={onShareHandler('twitter')}
                    >
                        <XIcon size={isTablet ? 32 : 60} round />
                    </TwitterShareButton>
                    <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}&text=${encodeURIComponent(
                            t('text_to_share')
                        )}`}
                        target="_blank"
                        className="Demo__some-network__share-button"
                        rel="noreferrer"
                        onClick={onShareHandler('linkedin')}
                    >
                        <LinkedinIcon size={isTablet ? 32 : 60} round />
                    </a>
                    {language === 'zh-CN' && (
                      <WeiboShareButton
                        id="weibo-share-button"
                        url={shareUrl}
                        title={t('text_to_share')}
                        className={clsx('Demo__some-network__share-button', styles.buttonsWithImgIcons, styles.weiboShareBtn)}
                        onClick={onShareHandler('weibo')}
                      >
                          <img src={weibo} alt="" />
                      </WeiboShareButton>
                    )}
                    <WhatsappShareButton
                        id="whatsapp-share-button"
                        url={shareUrl}
                        title={t('text_to_share')}
                        separator=" "
                        className="Demo__some-network__share-button"
                        onClick={onShareHandler('whatsapp')}
                    >
                        <WhatsappIcon size={isTablet ? 32 : 60} round />
                    </WhatsappShareButton>
                    <a
                        href={`mailto:?subject=${title}&body=${t(
                            'text_to_share'
                        )} %0D%0A ${shareUrl}`}
                        target="_blank"
                        className="Demo__some-network__share-button"
                        rel="noreferrer"
                        onClick={onShareHandler('email')}
                    >
                        <EmailIcon size={isTablet ? 32 : 60} round />
                    </a>
                    <button
                        type="button"
                        id="copy-link-button"
                        title={t('copy_link_to_clipboard')}
                        onClick={copyToClipboard}
                        className={clsx(styles.buttonsWithImgIcons, styles.copyClipboardBtn, {
                            active: isCopied,
                        })}
                    >
                        {isCopied ? (
                            <img src={checkIcon} alt="" />
                        ) : (
                            <img src={copyIcon} alt="" />
                        )}
                    </button>
                </div>
                <Button
                    type="button"
                    id="close-share-dialog-button"
                    title={t('close_dialog')}
                    className={styles.btn}
                    onClick={handleClose}
                >
                    {t('close')}
                </Button>
            </div>
        </Dialog>
    );
};
export default ResultShare;
