import axios, { AxiosError } from 'axios';
import {
    FaceImageLandmarks,
    FaceSwapVideo,
    FaceSwapVideoResult,
    Job,
    LanguageInputType,
} from './FaceSwap';

export interface APIResult {
    success: boolean;
    error?: string;
}

export interface ImageUploadResult extends APIResult {
    result: FaceImageLandmarks | any;
}

export class FaceSwapAPI {
    private static apiUrl = process.env.REACT_APP_API_URL;
    private static abortController: AbortController = new AbortController();

    /**
     * Job polling interval (seconds)
     */
    public static get jobPollInterval(): number {
        return 10;
    }

    public static get urlSwaps(): string {
        return this.apiUrl + '/api/swaps';
    }
    public static get urlImageUpload(): string {
        return this.apiUrl + '/api/image/upload';
    }
    public static get urlJobCreate(): string {
        return this.apiUrl + '/api/job/create';
    }
    public static get urlJobNotify(): string {
        return this.apiUrl + '/api/job/notify';
    }
    public static get urlJobStatus(): string {
        return this.apiUrl + '/api/job/status';
    }
    public static get urlJobVideo(): string {
        return this.apiUrl + '/api/job/video';
    }
    public static get urlImageSkinType(): string {
        return this.apiUrl + '/api/image/skinType';
    }

    /**
     * Cache
     */
    public static videos: FaceSwapVideo[] = [];

    public static videoByID(videoId: string): FaceSwapVideo | undefined {
        return FaceSwapAPI.videos.find((video) => video.id === videoId);
    }

    public static videoByLowerCaseTitle(
        videoTitle: string
    ): FaceSwapVideo | undefined {
        return FaceSwapAPI.videos.find(
            (video) => video.title.toLowerCase() === videoTitle.toLowerCase()
        );
    }

    /**
     * Parses API error and standardizes
     * @param error
     * @returns error message
     */
    private static errorMessage(error: AxiosError | Error | any): string {
        try {
            return error.response.data.error;
        } catch {
            return error.message ? error.message : 'Request Error';
        }
    }

    /**
     * Populates load once data currently swap videos.
     */
    public static async load(campaignId: string, languageCode: LanguageInputType) {
        function delay(t: number) {
            return new Promise((resolve) => setTimeout(resolve, t));
        }

        try {
            const response = await axios.get(
                `${
                    FaceSwapAPI.urlSwaps
                }?campaignId=${campaignId}&languageCode=${languageCode?.toLowerCase()}`
            );

            FaceSwapAPI.videos = response.data;
        } catch (error) {
            await delay(500);
            try {
                const response = await axios.get(
                    `${
                        FaceSwapAPI.urlSwaps
                    }?campaignId=${campaignId}&languageCode=${languageCode?.toLowerCase()}`
                );

                FaceSwapAPI.videos = response.data;
            } catch (error) {
                FaceSwapAPI.videos = [];
                const message = '9001: ' + FaceSwapAPI.errorMessage(error);
                throw new Error(message);
            }
        }
    }

    /**
     * Sends a new skin type for an image.
     * @param imageId The ID of the image.
     * @param skinType The skin type to set.
     * @returns FaceImage, ID used for generating videos
     */

    public static async updateImageSkinType(
        imageId: string,
        skinType: number
    ): Promise<FaceImageLandmarks> {
        try {
            const response = await axios.post(
                FaceSwapAPI.urlImageSkinType,
                { id: imageId, skinType: skinType },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    signal: this.abortController.signal,
                }
            );
            return response.data as FaceImageLandmarks;
        } catch (error) {
            const message = '9007: ' + FaceSwapAPI.errorMessage(error);
            throw new Error(message);
        }
    }

    /**
     * Sends an image for valiation.
     * @param image image file.
     * @returns FaceImage, ID used for generating videos
     */

    public static async uploadImage(image: File): Promise<FaceImageLandmarks> {
        try {
            const formData = new FormData();
            formData.append('imageFile', image);
            const imageUrl = URL.createObjectURL(image);
            const response = await axios.post(
                FaceSwapAPI.urlImageUpload,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    signal: this.abortController.signal,
                }
            );

            return {
                id: response.data.id,
                url: imageUrl,
                skinType: response.data.skinType,
            };
        } catch (error) {
            const message = '9002: ' + FaceSwapAPI.errorMessage(error);
            throw new Error(message);
        }
    }

    /**
     * Create a face swap video job.
     * @param videoId The ID of the target video.
     * @param sourceIds Array source IDs @see uploadImage
     * @param targetIds Array of target IDs
     * @returns The job ID for monitoring progress @see jobStatus
     */
    public static async createJob(
        videoId: string,
        sourceIds: string[],
        targetIds: string[]
    ): Promise<string> {
        try {
            const response = await axios.post(
                FaceSwapAPI.urlJobCreate,
                {
                    videoId: videoId,
                    sourceIds: sourceIds,
                    targetIds: targetIds,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const { jobId } = response.data;
            return jobId;
        } catch (error) {
            const message = '9003: ' + FaceSwapAPI.errorMessage(error);
            throw new Error(message);
        }
    }

    /**
     * Used to set notifcation email for a job.
     * @param jobId The job ID @see createJob
     * @param email The email for notificatons should be validated client side.
     * @param recaptchaToken The recaptcha token
     * @returns success
     */
    public static async jobNotify(
        jobId: string,
        email: string,
        recaptchaToken: string | null
    ): Promise<boolean> {
        try {
            const response = await axios.post(
                FaceSwapAPI.urlJobNotify,
                { jobId: jobId, email: email, recaptcha: recaptchaToken },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            const { success } = response.data;
            return success;
        } catch (error) {
            const message = '9004: ' + FaceSwapAPI.errorMessage(error);
            throw new Error(message);
        }
    }

    /**
     * Used to monitor the status of a face swap video job.
     * @param jobId The job ID @see createJob
     * @returns status @see JobStatus
     */
    public static async jobStatus(jobId: string): Promise<Job> {
        try {
            const response = await axios.get(
                `${FaceSwapAPI.urlJobStatus}?jobId=${jobId}`
            );
            return response.data;
        } catch (error) {
            const message = '9005: ' + FaceSwapAPI.errorMessage(error);
            throw new Error(message);
        }
    }

    /**
     * Used to get the url of the video for a complated job.
     * @param jobId The job ID @see createJob
     * @returns url of video
     */
    public static async jobVideo(jobId: string): Promise<FaceSwapVideoResult> {
        try {
            const response = await axios.get(
                `${FaceSwapAPI.urlJobVideo}?jobId=${jobId}`
            );
            return response.data as FaceSwapVideoResult;
        } catch (error) {
            const message = '9006: ' + FaceSwapAPI.errorMessage(error);
            throw new Error(message);
        }
    }

    public static abortRequest() {
        this.abortController.abort();
        this.abortController = new AbortController();
    }
}
