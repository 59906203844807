import React, { useMemo } from 'react'
import Button from '../ui/Button';
import clsx from 'clsx';
import { FaceSwapAPI } from '../../bdreams/FaceSwapAPI';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SlideActions from '../SlideActions.tsx';
import UploadGuide from '../UploadGuide';
import styles from './styles.module.scss';
import Download from '../../assets/tsx-icons/Download';
import { FaceSwapItem } from '../../@types/face-swaps';
import cameraIcon from '../../assets/icons/camera.svg'
import Photo from '../../assets/tsx-icons/Photo'
import SkinTypeSelector from './SkinTypeSelector'

interface Props {
    isPictureApproved: boolean | null;
    setPicture: (item: string | null) => void;
    setChangeSource: (item: FaceSwapItem | undefined) => void;
    changeSoruce: FaceSwapItem | undefined;
    handlePrivacyAgreement: (
        e: React.MouseEvent,
        type: string,
        item: FaceSwapItem | undefined
    ) => void;
    handleFileChange: (e: any, type: 'upload' | 'camera') => void;
    setIsGuideOpened: (item: boolean) => void;
    isGuideOpened: boolean;
    isLoading: boolean;
    picture: string | null;
    pictureSkinType: number;
    setPictureSkinType: (item: number) => void;
    handleContinueUploadPicture: () => Promise<void>;
    lastUsedTargetElementType: string;
}

const AnalyzingPictureBox = ({
    isPictureApproved,
    setPicture,
    setChangeSource,
    changeSoruce,
    handlePrivacyAgreement,
    handleFileChange,
    setIsGuideOpened,
    isGuideOpened,
    isLoading,
    picture,
    handleContinueUploadPicture,
    lastUsedTargetElementType,
    pictureSkinType,
    setPictureSkinType
}: Props) => {
    const { t, i18n: { language } } = useTranslation();
    const isChineseLanguage = language === 'zh-CN';
    const isTablet = useMediaQuery('(max-width:768px)');

    const ReUploadButtonComponent = () => (lastUsedTargetElementType === 'photo' ? (
      <div className={styles.uploadBtnBox}>
        {
          isTablet ? (
            <>
              <input
                id={'new-upload-tablet-camera'}
                type="file"
                accept="image/*"
                capture="user"
                onClick={(e) =>
                  handlePrivacyAgreement(e, 'photo', changeSoruce)
                }
                onChange={(e) => {
                  handleFileChange(e, 'camera');
                }}
              />
              <label
                className={styles.uploadBtn}
                htmlFor={'new-upload-tablet-camera'}
                title={t('take_photo')}
              >
                <Photo/>
                {t('take_photo')}
              </label>
            </>
          ) : (
            <button
              type="button"
              id={`new-upload-desktop-camera`}
              onClick={(e) => {
                handlePrivacyAgreement(e, 'photo', changeSoruce);
              }}
              title={t('new_photo')}
              className={styles.uploadBtn}
            >
              <Photo/>
              {t('new_photo')}
            </button>
          )
        }
      </div>
    ) : (
      <div className={styles.uploadBtnBox}>
        <input
          id={'new-upload-file'}
          type="file"
          accept="image/*"
          onClick={(e) => {
            handlePrivacyAgreement(
              e,
              'input',
              changeSoruce
            );
          }}
          onChange={(e) => {
            handleFileChange(e, 'upload');
          }}
        />
        <label
          className={styles.uploadBtn}
          htmlFor={'new-upload-file'}
          title={t('new_upload')}
        >
          <Download />
          {t('new_upload')}
        </label>
      </div>
    ))

    return (
        <>
            {isTablet && (
                <>
                    {isPictureApproved === null && (
                        <Button
                            type="button"
                            id="cancel-picture-button"
                            color="white"
                            onClick={() => {
                                FaceSwapAPI.abortRequest();
                                setPicture(null);
                            }}
                        >
                            {t('cancel')}
                        </Button>
                    )}
                    {isPictureApproved === true && (
                        <div className={styles.mobileFooter}>
                            <div className={styles.btnGroup}>
                              <SkinTypeSelector
                                pictureSkinType={pictureSkinType}
                                setPictureSkinType={setPictureSkinType} />
                                <div className={styles.btnGroup}>
                                    <Button
                                        type="button"
                                        id="approved-continue-button"
                                        color="white"
                                        disabled={pictureSkinType === 0}
                                        onClick={async() => {
                                            setPicture(null);
                                            await handleContinueUploadPicture();
                                        }}
                                        title={t('title_continue_photo')}
                                    >
                                        {t('continue')}
                                    </Button>
                                </div>
                                <ReUploadButtonComponent />
                            </div>
                        </div>
                    )}
                    {isPictureApproved === false && (
                        <div className={styles.mobileFooter}>
                            {/* <SlideActions
                                inputId={`rephotograph-input`}
                                setChangeSource={setChangeSource}
                                changeSoruce={changeSoruce}
                                handlePrivacyAgreement={handlePrivacyAgreement}
                                handleFileChange={handleFileChange}
                            /> */}
                            <div className={styles.btnGroup}>
                                <Button
                                    type="button"
                                    id="cancel-picture-button"
                                    color="white"
                                    onClick={() => {
                                        setPicture(null);
                                    }}
                                >
                                    {t('cancel')}
                                </Button>
                                <Button
                                    type="button"
                                    id="upload-guide-button"
                                    variant="outlined"
                                    className={styles.uploadBtn}
                                    onClick={() => setIsGuideOpened(true)}
                                >
                                    {t('upload_guide')}
                                </Button>
                                <UploadGuide
                                    open={isGuideOpened}
                                    handleClose={() => setIsGuideOpened(false)}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}

            <div
                className={clsx(styles.uploadedPictureBox, {
                    [styles.loading]: isLoading,
                    [styles.approved]: isPictureApproved === true,
                    [styles.denied]: isPictureApproved === false,
                })}
                title={
                    isLoading
                        ? t('your_picture_analyze')
                        : isPictureApproved
                          ? t('your_picture_approved')
                          : t('your_picture_rejected')
                }
            >
                <div className={styles.uploadedPicture}>
                    <img src={picture || ''} alt="" />
                </div>
            </div>

            <div className={clsx(styles.analyzingBox, { [styles.analyzingBoxChinese]: isChineseLanguage })}>
                {isPictureApproved === null && (
                    <>
                        <p className={clsx('h1', styles.analysingTitle)}>
                            {t('analyzing_photo')}
                        </p>
                        {!isTablet && (
                            <Button
                                type="button"
                                id="cancel-picture-button"
                                color="white"
                                onClick={() => {
                                    FaceSwapAPI.abortRequest();
                                    setPicture(null);
                                }}
                                title={t('cancel')}
                            >
                                {t('cancel')}
                            </Button>
                        )}
                    </>
                )}
                {isPictureApproved === true && (
                    <>
                        <p className="h1">{t('perfect_shot')}</p>
                        {!isTablet && (
                            <div className={styles.btnGroup}>
                                <SkinTypeSelector
                                  pictureSkinType={pictureSkinType}
                                  setPictureSkinType={setPictureSkinType} />
                                <Button
                                    type="button"
                                    id="approved-continue-button"
                                    color="white"
                                    disabled={pictureSkinType === 0}
                                    onClick={async() => {
                                        setPicture(null);
                                        await handleContinueUploadPicture();
                                    }}
                                    title={t('title_continue_photo')}
                                >
                                    {t('continue')}
                                </Button>
                                <ReUploadButtonComponent />
                            </div>
                        )}
                    </>
                )}
                {isPictureApproved === false && (
                    <>
                        <div>
                            <h1 className="h1">{t('please_try_again')}</h1>
                            <p className="body1">{t('follow_the_guides')}</p>
                        </div>
                        {!isTablet && (
                            <>
                                <SlideActions
                                    inputId={`rephotograph-input`}
                                    setChangeSource={setChangeSource}
                                    changeSoruce={changeSoruce}
                                    handlePrivacyAgreement={
                                        handlePrivacyAgreement
                                    }
                                    handleFileChange={handleFileChange}
                                    className={styles.analysingSlideActions}
                                    slideActionsClassName={
                                        styles.slideActionsClassName
                                    }
                                />
                                <div className={styles.btnGroup}>
                                    <Button
                                        type="button"
                                        id="cancel-picture-button"
                                        color="white"
                                        onClick={() => {
                                            setPicture(null);
                                        }}
                                        title={t('cancel')}
                                    >
                                        {t('cancel')}
                                    </Button>
                                    <Button
                                        type="button"
                                        id="upload-guide-button"
                                        variant="outlined"
                                        className={styles.uploadButton}
                                        onClick={() => setIsGuideOpened(true)}
                                        title={t('title_upload_guide')}
                                    >
                                        {t('upload_guide')}
                                    </Button>
                                    <UploadGuide
                                        open={isGuideOpened}
                                        handleClose={() =>
                                            setIsGuideOpened(false)
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
};
export default AnalyzingPictureBox;
