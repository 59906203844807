import React from 'react';

const ChevronDown = () => {
    return (
        <svg
            width="23"
            height="12"
            viewBox="0 0 23 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                id="Path 2642"
                d="M11.491 11.9999C11.2608 11.9999 11.0306 11.9142 10.8713 11.7427L0.247697 1.45703C-0.0887152 1.11418 -0.0887152 0.565605 0.265403 0.239891C0.619522 -0.0858231 1.1507 -0.0858231 1.50482 0.239891L11.5087 9.92561L21.4948 0.239891C21.8489 -0.0858231 22.3978 -0.0858231 22.7519 0.257034C23.0883 0.582748 23.0883 1.11418 22.7519 1.45703L12.1284 11.7427C11.969 11.897 11.7388 11.9999 11.5087 11.9999H11.491Z"
                fill="white"
            />
        </svg>
    );
};
export default ChevronDown;
