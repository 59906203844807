export interface FaceImage {
    id: string;
    url: string;
}

export interface FaceImageLandmarks extends FaceImage {
    id: string;
    skinType: number;
    url: string;
}

export interface FaceTarget extends FaceImage {
    title: string;
    description: string;
    urlThumb: string;
    urlThumbWide: string;
}

export interface FaceSwapVideo {
    id: string;
    title: string;
    description: string;
    url: string;
    targets: FaceTarget[];
}

export interface FaceSwapVideoResult {
    id: string;
    title: string;
    url: string;
    posterUrl: string;
    posterTimeSecs: number;
    landingUrl: string;
    shortUrl: string;
    swapVideoId: string;
}

export interface FaceSwap {
    source: FaceImage;
    target: FaceTarget;
}

export enum JobStatus {
    Pending = 'PENDING',
    Queue = 'QUEUE',
    Processing = 'PROCESSING',
    Download = 'DOWNLOAD',
    Success = 'SUCCESS',
    Failed = 'FAILED',
}

export enum Language {
    EN = 'en',
    AR = 'ar',
    CN = 'zh-CN',
    DE = 'de',
    FR = 'fr',
    IT = 'it',
    ES = 'es',
    PT = 'pt-BR',
}

export type LanguageInputType = 'en' | 'ar' | 'zh-CN' | 'de' | 'fr' | 'it' | 'es' | 'pt-BR';

export interface Job {
    /**
     * The current status of a job @see JobStatus
     */
    status: JobStatus;
    /**
     * Job estimated current progress 0..1
     */
    progress: number;
    /**
     * User facing message, should be update with each poll.
     */
    progressMessage: string;

    swapVideoId: string;
}
