import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en/translation.json';
import arTranslation from './ar/translation.json';
import cnTranslation from './zh-CN/translation.json';
import deTranslation from './de/translation.json';
import frTranslation from './fr/translation.json';
import esTranslation from './es/translation.json';
import itTranslation from './it/translation.json';
import ptTranslation from './pt-BR/translation.json';

const getNavigatorLanguage = () => {
    const isChineseLanguage = navigator?.language?.split('-')?.[1] === 'CN';
    // const isPortugueseLanguage = navigator?.language?.split('-')?.[0]?.toLowerCase() === 'pt';

    const navigatorLanguage = isChineseLanguage ? 'zh-CN' /*: isPortugueseLanguage ? 'pt-BR'*/ : navigator?.language?.split('-')?.[0] || 'en';
    if (['en', 'ar', 'zh-CN', /*'de', 'fr', 'es', 'it', 'pt-BR'*/].includes(navigatorLanguage)) {
        return navigatorLanguage;
    } else {
        return 'en';
    }
};

const languageWithCountry = {
    'zh': 'zh-CN',
    'pt': 'pt-BR'
}

let selectedLanguage =
    localStorage.getItem('user-selected-language') ||
    getNavigatorLanguage() ||
    'en';

const windowQueryParams = new URLSearchParams(window.location.search);
const queryLang = windowQueryParams.get('selLang');
if (!queryLang) {
    windowQueryParams.set('selLang', selectedLanguage.toLowerCase());
    window.location.search = windowQueryParams.toString();
} else {
    if (queryLang?.includes('-')) {
        windowQueryParams.set('selLang', queryLang.split('-')[0].toLowerCase());
        window.location.search = windowQueryParams.toString();
    }
    selectedLanguage = `${queryLang.split('-')[0].toLowerCase()}${
        queryLang.split('-')?.length > 1
            ? `-${queryLang.split('-')[1]?.toUpperCase() || ''}`
            : ''
    }`;
    if (languageWithCountry[selectedLanguage as keyof typeof languageWithCountry]) {
        selectedLanguage = languageWithCountry[selectedLanguage as keyof typeof languageWithCountry];
    }
    localStorage.setItem('user-selected-language', selectedLanguage);
}
if (selectedLanguage === 'ar') {
    document.body.dir = 'rtl';
} else {
    document.body.dir = 'ltr';
}

i18next.use(initReactI18next).init({
    lng: selectedLanguage,
    resources: {
        en: {
            translation: enTranslation,
        },
        'zh-CN': {
            translation: cnTranslation,
        },
        ar: {
            translation: arTranslation,
        },
        // de: {
        //     translation: deTranslation,
        // },
        // fr: {
        //     translation: frTranslation,
        // },
        // es: {
        //     translation: esTranslation,
        // },
        // it: {
        //     translation: itTranslation,
        // },
        // 'pt-BR': {
        //     translation: ptTranslation,
        // }
    },
    returnNull: false,
});
