import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const commonContext = createContext<any>(undefined);

const useProviderCommon = () => {
    const location = useLocation();
    const [isCameraOn, setIsCamerOn] = useState<boolean>(false);

    useEffect(() => {
        setIsCamerOn(false);
    }, [location]);

    return {
        isCameraOn,
        setIsCamerOn,
    };
};

export const CommonProvider = ({ children }: any) => {
    const cartProvider = useProviderCommon();
    return (
        <commonContext.Provider value={cartProvider}>
            {children}
        </commonContext.Provider>
    );
};

export const useCommon = () => {
    return useContext(commonContext);
};
