import React from 'react';
import { TermsAgreement } from './TermsAgreement'
import { PrivacyAgreement } from './PrivacyAgreement'

interface Props {
    open: boolean;
    type: string;
    title: string;
    text: string;
    submitBtnText: string;
    handleOpen: () => void;
    handleClose: () => void;
    handleSubmit: () => void;
}

const AgreementsDialog = (props: Props) => {
    if (props.type === 'terms') {
        return <TermsAgreement {...props} />;
    }
    if (props.type === 'privacy') {
        return <PrivacyAgreement {...props} />;
    }
    return null;
};
export default AgreementsDialog;
