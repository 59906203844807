import React from 'react';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import cameraIcon from '../../assets/icons/camera.svg';
import uploadIcon from '../../assets/icons/upload.svg';
import { FaceSwapItem } from '../../@types/face-swaps';
import clsx from 'clsx';

interface Props {
    inputId: string;
    setChangeSource: (item: FaceSwapItem | undefined) => void;
    changeSoruce: FaceSwapItem | undefined;
    handlePrivacyAgreement: (
        e: React.MouseEvent,
        type: string,
        item: FaceSwapItem | undefined
    ) => void;
    handleFileChange: (e: any, type: 'upload' | 'camera') => void;
    className?: string;
    slideActionsClassName?: string;
}

const SlideActions = ({
    inputId,
    setChangeSource,
    changeSoruce,
    handlePrivacyAgreement,
    handleFileChange,
    className,
    slideActionsClassName,
}: Props) => {
    const { t } = useTranslation();
    const isTablet = useMediaQuery('(max-width:768px)');

    return (
        <div
            className={clsx(styles.slideActions, className)}
            aria-label={t('aria_label_photo_actions')}
        >
            <div
                className={clsx(styles.slideActionsBox, slideActionsClassName)}
            >
                <input
                    id={inputId + '-file'}
                    type="file"
                    accept="image/*"
                    onClick={(e) => {
                        handlePrivacyAgreement(e, 'input', changeSoruce);
                    }}
                    onChange={(e) => {
                        handleFileChange(e, 'upload');
                    }}
                />
                <label
                    className={styles.slideActionsLabel}
                    htmlFor={inputId + '-file'}
                    title={t('upload_photo')}
                >
                    <img src={uploadIcon} alt="" style={{ marginBottom: 2 }} />
                    {t('upload_photo')}
                </label>
            </div>

            {isTablet ? (
                <div
                    className={clsx(
                        styles.slideActionsBox,
                        slideActionsClassName
                    )}
                >
                    <input
                        id={inputId + '-camera'}
                        type="file"
                        accept="image/*"
                        capture="user"
                        // onClick={() => setChangeSource(changeSoruce)}
                        onClick={(e) =>
                            handlePrivacyAgreement(e, 'photo', changeSoruce)
                        }
                        onChange={(e) => {
                            handleFileChange(e, 'camera');
                        }}
                    />
                    <label
                        className={styles.slideActionsLabel}
                        htmlFor={inputId + '-camera'}
                        title={t('take_photo')}
                    >
                        <img src={cameraIcon} alt="" style={{ marginBottom: -2 }} />
                        {t('take_photo')}
                    </label>
                </div>
            ) : (
                <div
                    className={clsx(
                        styles.slideActionsBox,
                        slideActionsClassName
                    )}
                >
                    <button
                        type="button"
                        id={`${inputId}-take-photo-button-camera`}
                        onClick={(e) => {
                            handlePrivacyAgreement(e, 'photo', changeSoruce);
                        }}
                        title={t('take_photo')}
                    >
                        <img src={cameraIcon} alt="" style={{ marginBottom: -4 }} />
                        {t('take_photo')}
                    </button>
                </div>
            )}
        </div>
    );
};
export default SlideActions;
