export type DataLayerOptions = {
    [key: string]: any;
}
const isProduction = process.env.NODE_ENV === 'production';
export default function triggerDataLayer(event: string, options?: DataLayerOptions) {
  if (isProduction) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': event,
      ...(options || {}),
    });
  } else {
    console.log('Dev DataLayer event:', event, options);
  }
}
