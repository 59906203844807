const Photo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="24" viewBox="0 0 29 24" fill="none">
      <path d="M25.81 4.02667H21.1436L18.8236 0.306667C18.705 0.12 18.4941 0 18.27 0H10.7168C10.4927 0 10.2818 0.12 10.1632 0.306667L7.84318 4.02667H3.17682C1.43682 4.02667 0.0131818 5.44 0 7.21333V20.8133C0 22.5733 1.42364 24 3.17682 24H25.8232C27.5632 24 28.9868 22.5867 29 20.8133V7.21333C29 5.45333 27.5632 4.02667 25.8232 4.02667H25.81ZM27.6555 20.8133C27.6555 21.84 26.8118 22.6667 25.7968 22.6667H3.16364C2.14864 22.6667 1.31818 21.84 1.305 20.8133V7.21333C1.305 6.18667 2.14864 5.36 3.16364 5.36H8.19909C8.42318 5.36 8.63409 5.24 8.75273 5.05333L11.0727 1.33333H17.9009L20.2209 5.04C20.3395 5.24 20.5505 5.34667 20.7745 5.36H25.81C26.825 5.36 27.6555 6.18667 27.6686 7.21333V20.8133H27.6555Z" fill=""/>
      <path d="M15 6C11.1345 6 8 9.13454 8 13C8 16.8655 11.1345 20 15 20C18.8655 20 22 16.8655 22 13C22 9.13454 18.8655 6 15 6ZM15 18.5944C11.9076 18.5944 9.40562 16.0924 9.40562 13C9.40562 9.90763 11.9076 7.40562 15 7.40562C18.0924 7.40562 20.5944 9.90763 20.5944 13C20.5944 16.0924 18.0924 18.5944 15 18.5944Z" fill=""/>
    </svg>
  )
}

export default Photo;
