export default function EnText(){
  return (
    <ol className="decimal">
      <li>OVERVIEW</li>
      <br/>
      <p><b>Qatar Airways Group Q.C.S.C.</b>, (“<b>Qatar Airways</b>”, “<b>we</b>”, “<b>our</b>” or “<b>us</b>”) wants you to feel safe when using this website (“Microsite”) and is therefore committed to protecting your Personal Data and your right to privacy when you do.</p>
      <p>This Flyqatar.com Privacy Notice (“<b>Privacy Notice</b>”) (together with our <a target="_blank" rel="noreferrer" href="/termsandconditions?selLang=en">Terms and Conditions</a> and our <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/en/legal/cookie-policy.html">cookie policy</a> explains how Personal Data are or will be processed by Qatar Airways and our service providers. By “<b>Personal Data</b>” we mean any information relating to an identified or identifiable natural person.</p>
      <p>Please read the following carefully to understand how we process your Personal Data on this Microsite. Where you disclose Personal Data on behalf of another person, you undertake and will ensure that the individual whose Personal Data are supplied to Qatar Airways has authorised the disclosure and has been provided with a copy of this Privacy Notice before you disclose such Personal Data.</p>
      <p>If we change this Privacy Notice, we will inform you about the changes by publishing the updated version on our Microsite. Subject to applicable laws, the English version of this Privacy Notice will prevail over any version of this Privacy Notice translated in another language.</p>
      <li>WHEN DOES THIS PRIVACY NOTICE APPLY?</li>
      <br/>
      <p>This Privacy Notice applies to the processing of Personal Data on this Microsite.</p>
      <li>WHO IS RESPONSIBLE FOR THE PROCESSING OF PERSONAL DATA?</li>
      <br/>
      <p>Qatar Airways, with its registered office at “<b>Qatar Airways Tower 1, PO Box 22550, Doha, State of Qatar</b>”, is responsible, as controller, for the processing of Personal Data described in this Privacy Notice.</p>
      <li>HOW WE COLLECT AND RECEIVE PERSONAL DATA</li>
      <br/>
      <p>We and our service providers may collect and receive Personal Data directly from you in a variety of ways, including:</p>
      <p>
        <u>Through the Microsite</u>: We may collect Personal Data through the Microsite. For example, when you utilise the services provided on the Microsite.<br/>
        <u>Offline</u>: At specific locations, such as shopping malls, we may collect your Personal Data based on your interest and willingness to utilise the services provided on the Microsite. For example, where you allow your photos taken via our official devices and uploading to the Microsite for receiving personalised video featuring you.<br/>
        Our Microsite may contain links to enable you to visit third-party websites. Please note that we do not have any control over how the controllers that are responsible for these third-party websites process your Personal Data and we are not responsible or liable for the way in which they operate or the way that they process your Personal Data. It is important that you understand this and check their respective privacy policies and website terms of use.
      </p>
      <li>WHAT PERSONAL DATA ARE COLLECTED</li>
      <br/>
      <p>
        “<b>Personal Data</b>” are information that identify you as an individual or relate to an identifiable individual, including:<br/>
        <ol className="roman">
          <li>Image / Photograph; and</li>
          <li>Email address.</li>
        </ol>
      </p>
      <p>We may need to collect and process these types of Personal Data in order to allow you to create a film as per our Terms and Conditions. If you do not provide the information that we request, you may not be able to participate in creating a film on our Microsite.</p>
      <li>WHAT IS THE PURPOSE OF THE COLLECTION OF PERSONAL DATA?</li>
      <br/>
      <p>We and our service providers may use Personal Data based on your consent.</p>
      <p>Your image or photograph may be used to create a film/ video as per your choice and allow you to participate and utilise the services provided under this Microsite. At offline locations, if you consent, your image or photograph and related film / video may be temporarily displayed on our digital billboard located in the same location.</p>
      <p>We use your email to send you the link to the film / video generated after utilising our Microsite. We do not use your data for any other purpose. Sharing your email address is optional if you wish to receive the personalised film / video in your email inbox.</p>
      <p>When you subscribe to our newsletter, we use your email address to send your updates via newsletters. You can always opt out from receiving them by using unsubscribe link provided within every newsletter.</p>
      <li>AUTOMATED DECISION-MAKING</li>
      <br/>
      <p>We use artificial intelligence (“<b>AI</b>”) to generate your personalised film/ video.</p>
      <p>Face swapping technology utilizes advanced machine learning algorithms and artificial intelligence to identify and replace one person's face with another in images or videos. The process typically involves face detection, feature extraction, and face synthesis. The algorithm first detects faces in the content, then analyses key facial features, and finally blends the replacement face into the original image or video while matching skin tone, lighting, and expressions to ensure a seamless transition.</p>
      <li>COOKIES</li>
      <br/>
      <p>Cookies are small files of information which save and retrieve information about your visit to our Microsite for example, how you entered our site, how you navigated through the site, and what information was of interest to you. Read more about how we use cookies in our <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/en/legal/cookie-policy.html">cookie policy</a>.</p>
      <li>DATA TRANSFERS</li>
      <br/>
      <p><u>WITHIN QATAR AIRWAYS GROUP</u></p>
      <p>Qatar Airways staff is authorised to access Personal Data only if and insofar as necessary to serve the applicable purpose and to perform their jobs.</p>
      <p>As part of a global organisation with its head office in Doha, State of Qatar, Personal Data we collect may be transferred internationally throughout Qatar Airways Group for business or administrative purposes to countries where Qatar Airways is established and whose data protection laws may not be as extensive as those in the European Economic Area (“<b>EEA</b>”). Where necessary, Qatar Airways will ensure that adequate safeguards are in place to comply with the requirements for the international transfer of personal data under applicable data protection and privacy laws.</p>
      <p><u>OUTSIDE QATAR AIRWAYS GROUP</u><br/>We may be required to disclose certain of your Personal Data in the following cases:</p>
      <ol className="alpha">
        <li>to third parties including service providers to provide a service to, or perform a function for, us or who are otherwise appointed by us in connection with the services we offer you including those who are acting as our agent, including, without limitation support service providers, technology providers, specialised consultants, legal advisers, auditors;</li>
        <li>to third parties in connection with financing, securitization, insuring, restructuring, assignment or other disposal of all or part of our business or assets or in relation to defence of claims; and</li>
        <li>to official authorities, including law enforcement officials, courts and government and regulatory authorities: (a) if we believe disclosure is required by any applicable law, regulation or legal process (such as to respond to subpoenas or judicial orders); or (b) to protect and defend our rights, or the rights or safety of third parties, including to defend against legal claims.</li>
      </ol>
      <p>When Personal Data are disclosed to third parties, we will take the required contractual, technical and organisational measures to duly protect such Personal Data in accordance with applicable legislation. The abovementioned disclosures may involve the transfer of Personal Data to and from countries outside of your country of residence, including to and from countries whose data protection laws may not be as extensive as those in the EEA. Where necessary, Qatar Airways will ensure that adequate safeguards are in place to comply with the requirements for the international transfer of personal data under applicable data protection and privacy laws.</p>
      <li>HOW DO WE KEEP YOUR DATA SAFE?</li>
      <br/>
      <p>Qatar Airways has implemented measures to safeguard Personal Data, as we are committed to ensuring that Personal Data are secure. In an effort to prevent unauthorised access to, or disclosure of, Personal Data, we have put in place physical, technical, and administrative safeguards to protect Personal Data against accidental or unlawful destruction or accidental loss, damage, alteration, unauthorised disclosure or access, as well as all other forms of unlawful processing.<br/>
        Although we use reasonable and appropriate efforts to protect Personal Data, we cannot guarantee the security of Personal Data transmitted via the internet or similar connection. If you have a reason to believe that your interaction with us is no longer secure, please immediately notify us in accordance with the “<b>How to Contact Us</b>” section below.</p>
      <li>USE OF THE MICROSITE BY MINORS</li>
      <br/>
      <p>This Microsite is not directed to individuals under the age of eighteen (18), and we do not knowingly collect Personal Data from individuals under 18.</p>
      <li>DATA RETENTION</li>
      <br/>
      <p>We will retain Personal Data for as long as it is necessary to fulfil the specified purpose for which it was collected, the legal or business purposes of Qatar Airways as described in this Privacy Notice and in line with our data retention policies, or as required by relevant laws. When erasing Personal Data, we will take commercially reasonable and technically possible measures to make the Personal Data irrecoverable or irreproducible in accordance with the applicable laws.</p>
      <li>USE OF THE MICROSITE BY MINORS</li>
      <br/>
      <p>This Microsite is not directed to individuals under the age of eighteen (18), and we do not knowingly collect Personal Data from individuals under 18.</p>
      <li>YOUR RIGHTS</li>
      <br/>
      <p>You can exercise your rights to access, rectification, erasure, restrict processing, data portability, objection or rights in relation to automated individual decision-making, including profiling, in relation to the Personal Data that Qatar Airways processes at any time by sending a request via a dedicated ‘<a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/en/subject-access-request-form.html">subject access request</a>’ portal on the Contact Us page of qatarairways.com.qa. You may have other privacy rights, including rights under the California Consumer Privacy Act (“<b>CCPA</b>”), depending upon your country of residence.</p>
      <p>Before proceeding with your request, we will verify your identity to ensure that we provide the requested information to the data subject to whom it pertains. If you have authorised an agent to make a request on your behalf, we require that you or your authorised agent provide us with a copy of the written permission to submit such a request on your behalf.</p>
      <p>We will not charge a fee to respond to your request unless the request may be deemed as manifestly unfounded or excessive. Upon request, we can provide you with a list of third party personal information handlers under the Personal Information Protection Law of the People’s Republic of China. If you feel that we do not comply with applicable privacy rules you have the right to lodge a complaint with a competent data protection authority.</p>
      <p>If you no longer want to receive our newsletters, you can unsubscribe at any time by clicking the ‘unsubscribe’ link in any email containing our newsletter. In addition, you can raise an unsubscribe request by emailing or calling our Customer Care Center or by sending us a request by post to the following address:</p>
      <p>
        Qatar Airways Group Q.C.S.C.<br/>
        Qatar Airways Tower 1,<br/>
        PO Box 22550,<br/>
        Doha, State of Qatar.<br/>
        Attention: Qatar Airways Direct Marketing Team - Ecommerce
      </p>
      <p>When raising a request to unsubscribe by e-mail, call or post, it may take up to 30 working days to process your request and to update our systems to remove your email address from our mailing list.</p>
      <li>HOW TO CONTACT US OR LODGE A COMPLAINT</li>
      <br/>
      <p>Should you have any questions or concerns about the processing of your Personal Data or this Privacy Notice, please contact:</p>
      <p>Email: <a target="_blank" rel="noreferrer" href="mailto:digitalbrand@qatarairways.com.qa">digitalbrand@qatarairways.com.qa</a></p>
      <p>
        Qatar Airways Group Q.C.S.C.<br/>
        Qatar Airways Tower 1,<br/>
        PO Box 22550,<br/>
        Doha, State of Qatar.<br/>
        Attention: Marketing Department
      </p>
      <p>You can also contact our Data Protection Officer by sending an email <a target="_blank" rel="noreferrer" href="mailto:dpo@qatarairways.com.qa">here</a>. Please note that you also have the right to lodge complaint with a competent supervisory authority.</p>
      <br/>
    </ol>
  )
}
