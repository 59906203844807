import Dialog from '@mui/material/Dialog'
import React from 'react'
import styles from './styles.module.scss';
import downloadVideo from '../../assets/icons/download-video.svg';
import threeDots from '../../assets/icons/three-dots.svg';
import externalLink from '../../assets/icons/external-link.svg';
import arrowUp from '../../assets/icons/arrow-up.svg';
import whiteCloseModal from '../../assets/icons/white-close-modal.svg';
import { useTranslation } from 'react-i18next'

interface IProps {
  open: boolean;
  handleClose: () => void;
}

export default function SaveVideoInAppModal({open, handleClose}: IProps) {
  const { t } = useTranslation();
  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      disableScrollLock
      sx={{
        '& .MuiDialog-paper': {
          padding: 3,
          width: '100vw',
          height: '100vh',
          maxWidth: '100vw',
          maxHeight: '100vh',
          margin: 0,
          borderRadius: 0,
          backgroundColor: '#64003A',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative'
        },
      }}
    >
      <div className={styles.tapHereSection}>
        <img src={arrowUp} alt="" />
        <p>{t('tap_here')}</p>
      </div>
      <div className={styles.modalContainer}>
        <div className={styles.headerSection}>
          <img src={downloadVideo} alt="Download video" />
          <h2>{t('collect_instagram')}</h2>
        </div>
        <div className={styles.stepsSection}>
          <div className={styles.step}>
            <img src={threeDots} alt="Three dots" />
            <p>{t('tap_dots')}</p>
          </div>
          <div className={styles.step}>
            <img src={externalLink} alt="External link" />
            <p>{t('then')}&nbsp;<b>{t('open_external')}</b></p>
          </div>
        </div>
        <button
          className={styles.closeBtn}
          onClick={handleClose}
        >
          <img src={whiteCloseModal} alt=''/>{t('close')}
        </button>
      </div>
    </Dialog>
  )
}
