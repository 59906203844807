export default function EnText() {
  return (
    <div>
      <p>Please review these Terms of Use carefully before accessing and using this web app, any of its services, functions, materials or contents (“<b>Microsite</b>”). This Microsite is owned and operated by Qatar Airways Group Q.C.S.C a Qatari Shareholding Company located at Qatar Airways Tower 1, Airport Road, State of Qatar.</p>
      <p>BY ACCESSING USING, VIEWING, TRANSMITTING, CACHING OR STORING THIS MICROSITE (ai.flyqatar.com) YOU INDICATE YOUR AGREEMENT TO BE BOUND BY THESE TERMS AND CONDITIONS (THE "TERMS OF USE") IN CONSIDERATION OF THE ACCESS PROVIDED TO THE MICROSITE AS SET FORTH BELOW AND YOU WARRANT THAT YOU ARE OVER THE AGE OF LEGAL MAJORITY AND POSSESS THE LEGAL CAPACITY TO ENTER INTO THIS AGREEMENT AND TO USE THIS MICROSITE IN ACCORDANCE WITH ALL TERMS OF USE HEREIN. YOU AGREE TO BE FINANCIALLY RESPONSIBLE FOR ALL OF YOUR USE OF THIS SITE (AS WELL AS FOR USE OF YOUR ACCOUNT BY OTHERS).</p>
      <p>“You” and “Your” refers to the persons accessing this Microsite (including persons who access this site on the behalf of other persons. “Us”, “We”, “Our” and similar terms refers to Qatar Airways. If You do not agree to these Terms of Use, any of the related documentation linked below or other restrictions notified to You during the course of Your use of this Microsite, You are not permitted to, and agree not to, use or access this Microsite.</p>
      <p>These Terms of Use govern Your use of the Microsite, and all applications, software, and services (collectively, "Services") available on the Microsite, except to the extent such Services are the subject of a separate agreement. Specific conditions may apply to the use of certain Services and other items provided to You on the Microsite ("Service Agreement(s)"). Capitalised terms which are not otherwise defined in these Terms of Use shall bear the meanings which they are given in the Definitions section attached at the end of these Terms of Use.</p>
      <h3>Terms of use</h3>
      <h3>Your use of the Microsite</h3>
      <p>Subject always to Your compliance with these Terms of Use, You may choose to submit your image and likeness via photographs (“<b>Your Content</b>”) to the Microsite for inclusion into the film video available on the Microsite (“<b>Film</b>”) (the “<b>Purpose</b>”). The Film incorporating Your Content shall be referred to in these Terms of Use as the Modified Film.</p>
      <p>By submitting Your Content to the Microsite for inclusion into the Film, You:</p>
      <ol className="alpha">
        <li>grant us a worldwide, non-exclusive, royalty-free, transferable, perpetual, non-revocable, sub-licensable right to use, reproduce, modify and create derivative works of Your Content for the Purpose;</li>
        <br/>
        <li>
          represent, warrant and undertake to us that:
          <ol className="roman">
            <li>You are able to grant us the rights to Your Content for the Purpose;</li>
            <li>You have and will continue to have all rights, title and interest in and to Your Content necessary for us to use Your Content for the Purpose;</li>
            <li>our use of Your Content in accordance with and in the manner intended or permitted by these Terms of Use will not infringe the Intellectual Property Rights of any third party or breach any applicable law; and</li>
            <li>neither Qatar Airways, nor any other person acting at the direction or with the permission of Qatar Airways (including, Qatar Airways Group members), will be liable to pay any third party any licence or other fee in respect of the use of Your Content in accordance with and in the manner intended or permitted by these Terms of Use.</li>
          </ol>
        </li>
      </ol>
      <br/>
      <p>You shall notify us as soon as practicable upon becoming aware of any infringement or suspected or alleged infringement by Your Content of the Intellectual Property Rights of any third party and will fully co-operate with us in any legal action taken by us to enforce our Intellectual Property Rights.</p>
      <p>Subject always to Your compliance with these Terms of Use, we grant to You a worldwide, limited, personal, revocable, royalty-free, non-transferable, non-sublicensable right to use, download, reproduce, upload, transmit, translate and modify the Modified Film for Your personal purposes only (and not for any commercial purposes whatsoever).</p>
      <p>Any rights not expressly granted to You in these Terms of Use are reserved to us.</p>
      <h3>License and ownership – Qatar Airways</h3>
      <p>Any and all Intellectual Property Rights associated with the Microsite and its contents, including the Film and the Modified Film (the "<b>Content</b>") are the sole property of Qatar Airways Group Q.C.S.C or third parties. Our Microsite’s Content is protected by applicable copyright and other laws.</p>
      <p>Elements of the Microsite are also protected by trade secret, unfair competition, and other laws and may not be copied or imitated in whole or in part. All custom graphics, icons, and other items that appear on the Microsite are trade marks, or service marks ("Marks") of Qatar Airways, its affiliates or other entities that have granted Qatar Airways the right and license to use such Marks and may not be used or interfered with in any manner without the express written consent of Qatar Airways.</p>
      <p>Except as otherwise expressly authorized by these Terms of Use, You may not copy, reproduce, modify, lease, loan, sell, create derivative works from, upload, transmit, or distribute the Intellectual Property of the Microsite in any way without Qatar Airways' or the appropriate third party's prior written permission. Except as expressly provided herein, Qatar Airways does not grant to You any express or implied rights to Qatar Airways' or any third party's Intellectual Property Rights.</p>
      <p>Without prejudice to the other terms in these Terms of Use, Qatar Airways grants You a limited, personal, nontransferable, non sublicensable, revocable license to (a) access and use only the Microsite, Content and Services only in the manner presented by Qatar Airways, and (b) access and use the Qatar Airways computer and network services offered within the Microsite (the "Qatar Airways Systems") only in the manner expressly permitted by Qatar Airways.</p>
      <p>Except for the limited license granted in these Terms of Use, Qatar Airways does not convey any interest in or to Qatar Airways Systems, information or data available via Qatar Airways Systems (the "Information"), Content, Services, Microsite or any other Qatar Airways property by permitting You to access the Microsite.</p>
      <p>Except to the extent required by law or as expressly provided herein, none of the Content and/or Information may be reverse-engineered, modified, reproduced, republished, translated into any language or computer language, re-transmitted in any form or by any means, resold or redistributed without the prior written consent of Qatar Airways. Without prejudice to the foregoing, You agree not to: (a) copy, display, modify, reproduce, or otherwise transfer any of the Materials to any third party without the prior written permission of Qatar Airways; (b) interfere with or disrupt networks connected to the Site; (c) use or attempt to use any device, software or routine which interferes with the proper functioning of the Site or any transactions being offered through the Site; (d) transmit files, data or other materials containing a computer virus, corrupted data, worms, “Trojan horses” or other instructions or design that would erase data or programming or cause the Site or any equipment or system to become inoperable or incapable of being used in the full manner for which it was designed; (e) deliver any communication to or through the Site which violates any local, state, federal or international law; (f) deliver any communication to or through the Site that contains defamatory, libelous, abusive or obscene material; (g) deliver any communication to or through the Site that will infringe upon the rights of any third party.</p>
      <p>In addition, in respect of the Content, Your display, use, transmission, reproduction or sharing of the Modified Film and/or Your incorporation of the Modified Film into any content, You shall not:</p>
      <ol className="alpha">
        <li>pretend to be us, any of our Affiliates, any member of Qatar Airways Group or an agent of us or our Affiliates, or otherwise misrepresent Your identity or affiliation;</li>
        <li>forge headers or otherwise manipulate identifiers (including URLs) so as to mislead or potentially mislead third parties as to Your identity or affiliate with us;</li>
        <li>engage in activities that would violate any fiduciary relationship, any applicable local, state, national, or international law, or any regulations having the force of law;</li>
        <li>transmit, spam or upload, post or otherwise transmit the Modified Film in any way that is inaccurate, harmful, obscene, defamatory, racist or is otherwise offensive to others;</li>
        <li>do anything or omit to do anything which would be injurious to our interests or that of Qatar Airways Group, as may be determined by us in our sole and absolute discretion;</li>
        <li>remove any proprietary notices from the Modified Film;</li>
        <li>use the Modified Film in any manner or for any purpose that infringes, misappropriates, or otherwise infringes any Intellectual Property Right or other right of any person, or that violates any applicable law;</li>
        <li>access all or any part of the Content to build a product and/or service which competes with the Content or any aspect thereof; or</li>
        <li>commercially exploit, sell, license or distribute any of the Content.</li>
      </ol>
      <br/>
      <p>Furthermore, You shall comply with such other additional guidelines in respect of the Modified Film as we may issue from time to time.</p>
      <h3>Copyright and trade mark information</h3>
      <p>All materials on this Microsite the underlying software code or software data are protected by copyright, trade mark, and/or other Intellectual Property Rights. These materials are owned by QATAR AIRWAYS GROUP Q.C.S.C or are used with permission from their owners or as otherwise authorized by law. Protected materials include, but are not limited to, the text, trade marks, source code, databases, logos, photographs, images, video clips, audio clips, designs, trade dress, and other materials contained in this Microsite, mobile site and mobile applications. All rights are reserved, worldwide.</p>
      <p>Save as expressly permitted under these Terms of Use, You shall not download, print, or reproduce portions of the Content for Your personal, non-commercial use, and enjoyment, and all other use of any Content (including, without limitation, photographs, articles, press releases, software, comments, tables or charts, video clips, logos) on this Microsite, mobile site and mobile applications is strictly prohibited. By way of illustration, such prohibited use may include the extraction, redistribution, republication, modification, broadcasting, sale, or otherwise making available of any of the information or material on this Microsite (in any format) to third parties. In addition to this restriction, You agree to comply with all copyright notices, information, or restrictions contained in any material accessed through this Microsite.<br/>Unauthorised use of this Microsite, mobile site and mobile applications or any of the information or material contained on this Microsite, or any of the information or material contained on this Microsite is illegal and as such may give rise to, amongst other things, a claim for damages and may also be a criminal offence.</p>
      <h3>Unauthorised actions or uses</h3>
      <p>You agree that your use of this Microsite is personal to you and that this Microsite is provided solely to permit you to view and purchase our products and services and for no other purpose. In particular, you agree that you will not make any unauthorised, false or fraudulent booking; that you will not attempt to interfere with the operation of this Microsite, or alter or corrupt any data or software.</p>
      <p>You are responsible for maintaining the confidentiality of Your Microsite access information (such as user names, passwords and personal identification numbers) and booking data (such as booking references and Your travel itinerary).</p>
      <p>You may choose to permit family members, friends or colleagues to access the Microsite on Your behalf by providing Microsite access information and/or booking data to such persons provided that:</p>
      <ol className="alpha">
        <li>You have obtained the consent of any other persons whose booking data or personal data is accessible with Your data (such as a group booking); and</li>
        <li>You accept full responsibility for the acts and omissions of that person when accessing the Microsite using the Microsite access information and/or booking data provided by you whether such access is authorised by You or not.</li>
      </ol>
      <br/>
      <p>You must not permit access to or use of this Microsite or any of the Material on or obtained from this Microsite to any commercial entity or other organisation providing a service to the public.</p>
      <h3>Your commitments</h3>
      <p>In addition to other restrictions set forth in these Terms of Use, You agree that:</p>
      <ol className="alpha">
        <li>You shall not disguise the origin of information transmitted through the Microsite.</li>
        <li>You will not use or access any service, information, application or software available via the Microsite in a manner not expressly permitted by Qatar Airways.</li>
        <li>You will not input or upload to the Microsite any information which contains viruses, Trojan horses, worms, time bombs or other computer programming routines that are intended to damage, interfere with, intercept or expropriate any system, the Microsite or Information or that infringes the Intellectual Property Rights of another.</li>
        <li>Certain areas of the Microsite are restricted to customers of Qatar Airways.</li>
        <li>You may not use or access the Microsite or the Qatar Airways Systems or Services in any way that, in Qatar Airways judgment, adversely affects the performance or function of the Qatar Airways Systems, Services or the Microsite or interferes with the ability of authorized parties to access the Qatar Airways Systems, Services or the Microsite.</li>
        <li>You may not frame or utilize framing techniques to enclose any portion or aspect of the Content or the Information, without the express written consent of Qatar Airways.</li>
      </ol>
      <br/>
      <p>You agree to follow and abide by these Terms of Use and by all instructions and procedures set out in this Microsite. You understand that a failure to follow all instructions and procedures may lead to your obligation to purchase unintended goods or services; forfeiture of your products or services; denial of access to this Microsite products, or services; and possibly legal action being brought against you.</p>
      <p>You acknowledge and agree that you remain responsible and liable for:</p>
      <ol className="alpha">
        <li>evaluating and bearing all risks associated with your use of the Microsite and its Content;</li>
        <li>ensuring adequate protection and backup of Your Content and/or systems used by you in connection with accessing and using the Microsite;</li>
        <li><b>your use of the Microsite and Content, whether such access or use is permitted by or in breach of these Terms of Use, including use with any mobile applications or third-party software.</b></li>
      </ol>
      <br/>
      <h3>Disclaimers and limitations of liability</h3>
      <ol className="alpha">
        <li>Whilst the Product Information and all other Microsite, Content, mobile site and mobile applications material are provided in good faith, by using this Microsite, Content, mobile site and mobile applications, You agree and acknowledge that Qatar Airways makes no representations, warranties, or claims as to the accuracy of the Product Information or any other material on this Microsite, its Content, mobile site and mobile applications. We do not represent or warrant that Your use of the Microsite will be uninterrupted or error-free, or that the Microsite or its Content will be free from Harmful Code. Furthermore, You agree that the Product Information and Microsite, its Content, mobile site and mobile applications material does not constitute any form of advice or recommendation to You.</li>
        <li>Qatar Airways disclaims all implied warranties relating to Your use of this Microsite, its Content, mobile site and mobile applications and the information, products, and services contained in this Microsite, its Content, mobile site and mobile applications. To the maximum extent permissible under law, all information on this Microsite, its Content, mobile site and mobile applications is provided without any warranty (either express or implied by law or otherwise) or implied term of any kind, including but not limited to any implied warranties or implied terms of satisfactory quality, fitness for a particular purpose, or non-infringement of third party rights.</li>
        <li>By using this Microsite, its Content, mobile site and mobile applications, You agree that Qatar Airways will not be liable to you for any direct, indirect, consequential, or any other loss arising from the use (or non-use) of the information, products, and services contained in this Microsite, its Content, mobile site and mobile applications or from your access of other material via links from this Microsite, its Content mobile site and mobile applications. The exclusions and limitations contained in these Terms of Use apply to the maximum extent permitted by law.</li>
        <li>Qatar Airways does not guarantee that use of this Microsite, its Content, mobile site and mobile applications will be compatible with all hardware and software which may be used by visitors to the site.</li>
        <li>Except as set out in sub-paragraphs f and g, Qatar Airways will be under no liability to you whatsoever, whether in contract, tort (including negligence), breach of statutory duty, restitution, or otherwise for any injury, death, damage, or direct, indirect or consequential loss (all three of which terms include, without limitation, pure economic loss, loss of profits, loss of business, depletion of goodwill, and like loss) howsoever caused arising out of or in connection with the use of this Microsite, its Content, mobile site and mobile applications or the use, accessing, downloading, or relying on any information or other materials contained in this Microsite, its Content, mobile site and mobile applications, including, without limitation, as a result of any computer virus.</li>
        <li>These Terms of Use do not exclude Qatar Airways’ liability (if any) to you for personal injury or death resulting from Qatar Airway's negligence, for fraud or for any matter which it would be illegal for Qatar Airways to exclude or to attempt to exclude its liability.</li>
        <li>Subject to sub-paragraph f, Qatar Airways' entire liability in respect of any matter or series of matters arising out of or in connection with Qatar Airways' provision of this Microsite, its Content, mobile site and mobile applications and any service by Qatar Airways or anything there under by any means shall be limited to USD 1,000 in total for each and every event or series of connected events.</li>
        <li>You shall indemnify Qatar Airways and its licensors and keep Qatar Airways and its licensors indemnified against all losses, damages, claims, and costs (including legal cost) caused or suffered by Qatar Airways and/or its licensors as a result of a breach by you of any of these Terms of Use.</li>
      </ol>
      <br/>
      <h3>Privacy and Cookies</h3>
      <p>Qatar Airways is committed to protecting your privacy and complying with applicable data protection and privacy laws and the protection of your Personal Data. By "Personal Data" we mean any information relating to an identified or identifiable natural person. Personal Data will be processed in accordance with our Privacy Notice available <a target="_blank" rel="noreferrer" href="/privacypolicy?selLang=en">here</a>.</p>
      <p>Cookies are small files of information, which save and retrieve information about your visit to our Microsite – for example, how you entered our site, how you navigated through the site and what information was of interest to you. Read more about how we use cookies in our Cookie Policy, which is available on our Microsite <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/en/legal/cookie-policy.html">here</a>.</p>
      <h3>Links</h3>
      <p>Linking to this Microsite is strictly prohibited without our consent in writing, which consent can be withdrawn at any time. Any Microsite or other device that links to http://www.qatarairways.com or any page available therein is prohibited from:</p>
      <ol className="alpha">
        <li>Replicating Content,</li>
        <li>Using a browser or border environment around the Content,</li>
        <li>Implying in any fashion that Qatar Airways or any of its affiliates are endorsing it or its products,</li>
        <li>Misrepresenting any state of facts, including its relationship with Qatar Airways or any of its affiliates,</li>
        <li>Presenting false information about Qatar Airways products or services, and</li>
        <li>Using any logo or mark of Qatar Airways or any of its affiliates without express written permission from Qatar Airways.</li>
      </ol>
      <br/>
      <p>The Microsite may contain links to third-party Microsites and resources (collectively, "Linked Sites"). These Linked Sites are provided solely as a convenience to You and not as an endorsement by Qatar Airways of the Content on such Linked Sites. Qatar Airways makes no representations or warranties regarding the correctness, accuracy, performance or quality of any Content, software, service or application found at any Linked Site. Qatar Airways is not responsible for the availability of the Linked Sites or the Content or activities of such sites. If You decide to access Linked Sites, You do so at Your own risk. In addition, your use of Linked Sites is subject to any applicable policies and terms and conditions of use, including but not limited to, the Linked Site's privacy and cookie policies.</p>
      <h3>Modifications to terms</h3>
      <p>These Terms of Use and/or other information services, products and Content provided on this Microsite may be updated, amended, changed, or removed by Qatar Airways at any time and without any notice to you. By using this Microsite, you are deemed to have agreed to be bound by the latest version of it.</p>
      <h3>Miscellaneous</h3>
      <p>Except as expressly provided in a separate agreement between you and Qatar Airways, these Terms of Use supersede all previous contracts, arrangements, representations (other than fraudulent misrepresentation), terms implied by law, and understandings between the parties whether written, arising from custom, orally, or otherwise concerning the subject matter hereof. These Terms of Use are written in the English language. The parties declare that they have required that these Terms of Use and all documents related hereto, either present or future, be drawn up in the English language only.</p>
      <h3>Severability and Waiver</h3>
      <p>If any part of these Terms of Use are found by any court, tribunal, administrative body, or authority of competent jurisdiction to be illegal, invalid, or unenforceable then that provision will, to the extent required, be severed from these Terms of Use and will be ineffective without, as far as possible, modifying any other parts of these Terms of User and this will not affect any other provisions of the amended Terms of Use, which will remain in full force and effect.</p>
      <p>There shall be no waiver of any term, provision, or condition of these Terms and Conditions, unless such waiver is evidenced in writing and signed by the party granting such waiver. There shall be no waiver of any term, provision, or condition of these Terms of Use, unless such waiver is evidenced in writing and signed by the party granting such waiver.</p>
      <h3>Governing Law and Jurisdiction</h3>
      <p>The Terms of Use of this Microsite shall be governed and construed in accordance with the laws of the State of Qatar. The Qatari courts shall have non-exclusive jurisdiction to adjudicate any disputes, which may arise as a result. To the fullest extent permitted by law, you agree that Qatar Airways reserves the right to bring an action against you in any country it deems appropriate.</p>
      <h3>Termination</h3>
      <p>You agree that Qatar Airways, in its sole discretion, may terminate or suspend Your use of the Microsite, and the Qatar Airways Systems, Information, Services and Content at any time and for any or no reason in its sole discretion, even if access and use continues to be allowed to others. Upon such suspension or termination, you must immediately (a) discontinue use of the Microsite, and (b) destroy any copies You have made of any portion of the Content. Accessing the Microsite and the Qatar Airways Systems, Information or Services after such termination, suspension or discontinuation shall constitute an act of trespass. Further, you agree that Qatar Airways shall not be liable to You or any third party for any termination or suspension of Your access to the Microsite and the Qatar Airways Systems, Information and/or the Services.</p>
      <h3>Contact Us</h3>
      <p>If you have any questions about our Microsite or our Terms of Use, please contact:</p>
      <p>Qatar Airways Group Q.C.S.C<br/>
        Qatar Airways Tower<br/>
        P.O. Box: 22550<br/>
        Doha, State of Qatar<br/>
        Email: <a target="_blank" rel="noreferrer" href="mailto:qatarairways.com/contactus">qatarairways.com/contactus</a>
      </p>
      <br/>
      <table>
        <tbody>
        <tr>
          <td colSpan={2}>Definitions</td>
        </tr>
        <tr>
          <td>Affiliate(s)</td>
          <td>means any entity or entities that directly or indirectly controls, is controlled by, or is under common control with, a party; </td>
        </tr>
        <tr>
          <td>Harmful Code </td>
          <td>means: (a) any virus, Trojan horse, worm, spyware, backdoor, trapdoor, fork bomb, logic bomb, malware or drop dead service; and (b) any other software routine intended or designed to be harmful, destructive or disruptive, to cause malfunctions, errors, defects, denial or degradation of service or loss, unauthorised access, modification, disclosure or corruption of data.</td>
        </tr>
        <tr>
          <td>Intellectual Property Rights </td>
          <td>means all industrial and intellectual property rights of any kind including but not limited to copyright (including rights in computer software), trade mark, service mark, design, patent, trade secret, semiconductor or circuit layout rights, trade, business, domain or company names, rights in Confidential Information, know how or other proprietary rights (whether or not any of these are registered and including any application for registration) and all rights or forms of protection of a similar nature or having equivalent or similar effect to any of these which may subsist anywhere in the world.</td>
        </tr>
        <tr>
          <td>Qatar Airways Group</td>
          <td>means Qatar Airways Group Q.C.S.C. and each of its subsidiaries, Affiliates, managed entities, permitted assigns and successors.</td>
        </tr>
        </tbody>
      </table>
      <br/>
    </div>
  )
}
