import React from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '../ui/Button';
import styles from './styles.module.scss';
import clsx from 'clsx';
import greenCheck from '../../assets/icons/guide-green-check.svg';
import redExclamation from '../../assets/icons/guide-red-exclamation.svg';
import goodPhotos from '../../assets/good-photos.png';
import goodPhotosMobile from '../../assets/good-photos-mobile.png';
import badPhotos from '../../assets/bad-photos.png';
import badPhotosMobile from '../../assets/bad-photos-mobile.png';
import exitIcon from '../../assets/icons/exit.svg';
import { useTranslation } from 'react-i18next';
import { SwipeableDrawer, useMediaQuery } from '@mui/material';

interface Props {
    open: boolean;
    handleClose: () => void;
}

const UploadGuide = ({ open, handleClose }: Props) => {
    const { t } = useTranslation();
    const isTablet = useMediaQuery('(max-width:768px)');

    const content = (
        <div className={clsx(styles.wrap, 'wrap')}>
            <button
                type="button"
                id="x-close-guide-button"
                className={styles.exitButton}
                onClick={handleClose}
                title={t('close')}
            >
                <img src={exitIcon} alt="" />
            </button>

            <Button
                type="button"
                id="mobile-close-guide-button"
                onClick={handleClose}
                className={styles.mobileButton}
                title={t('close')}
            >
                {t('close')}
            </Button>

            <div className={styles.leftCol}>
                <div className={styles.row} aria-label={t('good_photos')}>
                    <div className={clsx(styles.rowTitle, 'h2')}>
                        <div className={styles.icon}>
                            <img
                                src={greenCheck}
                                alt="Example of good photos"
                            />
                        </div>
                        {t('good_photos')}
                    </div>
                    <p className="body1">{t('face_camera')}</p>
                    <picture className={styles.img}>
                        <source
                            srcSet={goodPhotosMobile}
                            media="(max-width: 768px)"
                        />
                        <img src={goodPhotos} alt="" />
                    </picture>
                </div>
                <div className={styles.row} aria-label={t('bad_photos')}>
                    <div className={clsx(styles.rowTitle, 'h2')}>
                        <div className={styles.icon}>
                            <img
                                src={redExclamation}
                                alt="Example of bad photos"
                            />
                        </div>
                        {t('bad_photos')}
                    </div>
                    <p className="body1">{t('avoid_things')}</p>
                    <picture className={styles.img}>
                        <source
                            srcSet={badPhotosMobile}
                            media="(max-width: 768px)"
                        />
                        <img src={badPhotos} alt="" />
                    </picture>
                </div>
            </div>

            <div className={styles.rightCol}>
                <h1 className="h1">{t('getting_the_best_results')}</h1>
                <p className="body1">{t('follow_the_guides_guide')}</p>
                <Button
                    id="close-guide-button"
                    type="button"
                    onClick={handleClose}
                    title={t('close')}
                >
                    {t('close')}
                </Button>
            </div>
        </div>
    );

    return isTablet ? (
        <SwipeableDrawer
            disableSwipeToOpen
            anchor="bottom"
            open={open}
            onOpen={() => {}}
            onClose={handleClose}
            className={styles.dialog}
            sx={{
                backdropFilter: open ? 'blur(5px)' : 'none',
            }}
        >
            {content}
        </SwipeableDrawer>
    ) : (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            className={styles.dialog}
        >
            {content}
        </Dialog>
    );
};
export default UploadGuide;
