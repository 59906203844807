import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import styles from './styles.module.scss';
import volumeIcon from '../../../assets/icons/volume.svg';
import clsx from 'clsx';

interface Props {
    videoRef: any;
    className?: string;
}

const VideoVolume = ({ videoRef, className }: Props) => {
    const [videoVolume, setVideoVolume] = useState<number>(100);

    const handleVolumeChange = (e: Event, value: number | number[]) => {
        setVideoVolume(value as number);
        if (videoRef.current) {
            videoRef.current.volume = (value as number) * 0.01;
        }
        e.stopPropagation();
    };

    return (
        <div className={clsx(styles.videoVolume, className)}>
            <Slider
                orientation="vertical"
                value={videoVolume}
                onChange={handleVolumeChange}
                className={styles.slider}
            />

            <img src={volumeIcon} alt="" />
        </div>
    );
};
export default VideoVolume;
