export default function ArText(){
  return (
    <ol className="decimal">
      <li>نظرة عامة</li>
      <br/>
      <p>تريد لك
        &nbsp;<b>مجموعة الخطوط الجوية القطرية (ش.م.ق.م)</b>
        (ويُشار إليها فيما بعد باسم
        &nbsp;<b>"الخطوط الجوية القطرية"، أو "نحن" أو غير ذلك من ضمائر الجمع للمتكلم</b>
        ) أن تشعر بالأمان عند استخدام هذا الموقع الإلكتروني (
        &nbsp;<b>"الموقع"</b>
        )، ولهذا تلتزم بحماية بياناتك الشخصية وحقك في الخصوصية عند استخدامك له.</p>
      <p>وإشعار خصوصية Flyqatar.com هذا (
        <b>"إشعار الخصوصية"</b>
        )، ومعه
        &nbsp;<a target="_blank" rel="noreferrer" href="/termsandconditions?selLang=ar">الشروط والأحكام</a>&nbsp;
        الصادرة عنا
        &nbsp;<a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/ar/legal/cookie-policy.html">وسياستنا لملفات تعريف الارتباط</a>&nbsp;
        وسياستنا لملفات تعريف الارتباط، يشرح الأساليب التي تتبعها، أو ستتبعها، الخطوط الجوية القطرية ومقدمي الخدمات الذين تتعامل معهم في معالجة بياناتك الشخصية. وعند استخدام مصطلح
        &nbsp;<b>"البيانات الشخصية"</b>&nbsp;
        فإننا نقصد به أي معلومات تتعلق بشخص طبيعي معروف الهوية أو يمكن تحديد هويته.</p>
      <p>يُرجى قراءة ما يلي جيداً لتفهم طريقة معالجتنا لبياناتك الشخصية على هذا الموقع.
        <br/>
        وعند كشفك عن بيانات شخصية نيابةً عن شخص آخر، فأنت تتعهد بأن ذلك الشخص الذي تقدم بياناته إلى الخطوط الجوية القطرية قد صرح لك بالكشف عنها وحصل على نسخة من إشعار الخصوصية هذا قبل أن تكشف عن بياناته الشخصية، وستضمن تقديمه لذلك التصريح وحصوله على مثل تلك النسخة.
      </p>
      <p>إذا أدخلنا تغييرات على إشعار الخصوصية هذا، فسنبلغك بالتغييرات بنشر النسخة المعدَّلة على موقعنا. ومع مراعاة القوانين واجبة التطبيق، فإن النسخة الإنجليزية من هذا الإشعار لها الأولوية في التطبيق على أي ترجمة له إلى أي لغة أخرى.</p>
      <li>ما الحالات التي يسري عليها إشعار الخصوصية هذا؟</li>
      <br/>
      <p>يسري إشعار الخصوصية هذا على معالجة البيانات الشخصية على هذا الموقع.</p>
      <li>مَن المسؤول عن معالجة البيانات الشخصية؟</li>
      <br/>
      <p>الخطوط الجوية القطرية، ويقع مقرها الرسمي ف
        &nbsp;<b>"برج الخطوط الجوية القطرية رقم 1، ص.ب. 22550، الدوحة، دولة قطر"</b>&nbsp;
        ، هي المسؤولة، بصفتها الجهة المتحكمة في البيانات، عن معالجة البيانات الشخصية على النحو الموضح في إشعار الخصوصية هذا.</p>
      <li>أساليبنا في جمع البيانات الشخصية والحصول عليها</li>
      <br/>
      <p>يجوز لنا ولمقدمي الخدمات الذين نتعامل معهم جمع البيانات الشخصية أو الحصول عليها منك مباشرة بعدة طرق، ومنها:</p>
      <p>
        <u>من خلال الموقع</u>
        يجوز لنا جمع البيانات الشخصية عن طريق الموقع، وذلك مثلاً عندما تستخدم الخدمات المقدمة عليه.
        <br/>
        <u>في المواقع العامة</u>
        في أماكن عامة معينة، مثل مراكز التسوق التجارية، يجوز لنا جمع بياناتك الشخصية استناداً إلى اهتمامك بالاستفادة من الخدمات المقدمة على الموقع واستعدادك لذلك. ومن أمثلة ذلك، سماحك بالتقاط صور لك عن طريق أجهزتنا الرسمية وتحميلها إلى الموقع للحصول على مقطع فيديو معد لك وتظهر فيه صورتك.
        <br/>
        قد يحتوي موقعنا على روابط تسمح لك بزيارة مواقع تابعة للغير. يُرجى العلم أننا ليس لنا أي سيطرة على معالجة الجهات المتحكمة في البيانات المسؤولة عن تلك المواقع لبياناتك الشخصية ولا نتحمل المسؤولية، بما في ذلك المسؤولية القانونية، عن طريقة عملهم ولا معالجتهم لبياناتك الشخصية. ومن الضروري أن تفهم ذلك وتراجع سياسات خصوصية تلك المواقع وشروط استخدامها.
      </p>
      <li>ما البيانات الشخصية التي تُجمع؟</li>
      <br/>
      <p>
        <b>"البيانات الشخصية"</b>&nbsp;
        هي بيانات يمكن تحديد هويتك الشخصية من خلالها أو تتعلق بشخص يمكن التعرف على هويته، وتشمل:
        <br/>
        <ol className="roman">
          <li>الصور/ الصور الفوتوغرافية؛ و</li>
          <li>البريد الإلكتروني التجاري.</li>
        </ol>
      </p>
      <p>قد نحتاج إلى جمع هذه الأنواع من البيانات الشخصية من أجل السماح لك بإعداد فيلم وفقاً للشروط والأحكام التي نطبقها. وإذا لم تقدم المعلومات التي نطلبها، فقد لا تتمكن من المشاركة في إعداد فيلم على موقعنا.</p>
      <li> ما الهدف من جمع البيانات الشخصية؟</li>
      <br/>
      <p>يجوز لنا ولمقدمي الخدمات الذين نتعامل معهم استخدام البيانات الشخصية بالاستناد إلى موافقتك.</p>
      <p>يجوز استخدام صورتك، بما في ذلك صورتك الفوتوغرافية، لإعداد فيلم/ مقطع فيديو حسب اختيارك، والسماح لك بالمشاركة في الخدمات المقدمة على هذا الموقع والاستفادة منها. وفي الأماكن العامة وبعد تقديمك لموافقتك، قد تُعرض صورتك، بما في ذلك صورتك الفوتوغرافية، مؤقتاً على لوحة الإعلانات الرقمية الخاصة بنا في المكان الذي شاركت فيه.</p>
      <p>نستخدم بريدك الإلكتروني لنرسل لك رابط الفيلم/ مقطع الفيديو الذي أُعد بعد استخدامك موقعنا، ولا نستخدم بياناتك لأي غرض آخر. وتقديم عنوان بريدك الإلكتروني إلينا أمر اختياري إذا رغبت في استلام الفيلم/ مقطع الفيديو المعد لك على بريدك الإلكتروني.</p>
      <p>عند اشتراكك في نشرتنا الإخبارية، فإننا نستخدم عنوان بريدك الإلكتروني لنرسل لك نشرات إخبارية تضم آخر أخبارنا. ويمكنك في أي وقت إنهاء اشتراكك في تلقي تلك النشرات باستخدام رابط إلغاء الاشتراك الموجود في كل نشرة.</p>
      <li> اتخاذ القرار آلياً</li>
      <br/>
      <p>نستخدم الذكاء الاصطناعي (
        <b>"AI"</b>
        ) لإعداد الفيلم/ مقطع الفيديو الخاص بك.</p>
      <p>وتستخدم تقنية تبديل الأوجه خوارزميات متقدمة لتعلم الآلة والذكاء الاصطناعي للتعرف على وجه شخص في الصورة ووضع صورة شخص آخر بدلاً منها. وتتضمن خطوات الاستبدال عادة تحديد الوجه ضمن المحتوى ثم تحليل أهم ملامحه ثم دمج الوجه البديل في الصورة الأصلية أو مقطع الفيديو الأصلي مع مطابقة درجة لون البشرة والإضاءة والتعبيرات من أجل ضمان دمج الوجه البديل بدون آثار لهذا الاستبدال.</p>
      <li>ملفات تعريف الارتباط</li>
      <br/>
      <p>ملفات تعريف الارتباط هي ملفات صغيرة تتضمن معلومات وتتولى حفظ واسترجاع معلومات عن زيارتك إلى موقعنا، مثل كيف دخلت إلى موقعنا أو كيف تنقلت بين أقسامه، وما المعلومات التي أثارت اهتمامك. ويمكنك معرفة أساليبنا في استخدام ملفات تعريف الارتباط ف
        &nbsp;<a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/en/legal/cookie-policy.html"> سياسة ملفات تعريف الارتباط</a>
        .</p>
      <li>نقل البيانات</li>
      <br/>
      <p><u>ضمن مجموعة الخطوط الجوية القطرية</u></p>
      <p>لا يُصرح لموظفي الخطوط الجوية القطرية بالوصول إلى البيانات الشخصية إلا بالقدر اللازم لتحقيق الغرض المعني وأداء واجبات وظائفهم.</p>
      <p>ولأن الخطوط الجوية القطرية مؤسسة عالمية يقع مقرها الرئيسي في الدوحة بدولة قطر، فإن البيانات الشخصية التي نجمعها قد تُنقل بين الدول التي توجد بها كيانات تابعة لمجموعة الخطوط الجوية القطرية لتحقيق أغراض تجارية أو إدارية، ويشمل ذلك النقل إلى بلدان قد تكون قوانينها لحماية البيانات أقل شمولاً من قوانين حماية البيانات في المنطقة الاقتصادية الأوروبية (
        <b>"EEA"</b>
        ). وعند الضرورة، ستتحقق الخطوط الجوية القطرية من توافر الضمانات الكافية للالتزام باشتراطات نقل البيانات الشخصية بين الدول وفقاً لقوانين حماية البيانات والخصوصية واجبة التطبيق.
      </p>
      <p><u>خارج مجموعة الخطوط الجوية القطرية</u><br/>
        قد نكون مطالبين بالكشف عن بعضٍ من بياناتك الشخصية في الحالات التالية:</p>
      <ol className="alpha">
        <li> إلى الغير، ومنهم مقدمو الخدمات الذين نتعامل معهم لتقديم خدمة إلينا أو أداء وظيفة لنا أو مقدمو الخدمات الذين نستعين بهم على أي نحو آخر فيما يتعلق بالخدمات التي نقدمها إليك، ويشمل هؤلاء مقدمو الخدمات الذين يتصرفون بوصفهم وكلاء لنا والذين يشملون على سبيل المثال لا الحصر مقدمي خدمات الدعم ومقدمي الخدمات التكنولوجية والاستشاريين المتخصصين والمستشارين القانونيين والمدققين</li>
        <li> إلى الغير، فيما يتصل بتمويل أعمالنا أو أصولنا كلها أو بعضها أو توريقها أو التأمين عليها أو إعادة هيكلتها أو التنازل عنها أو التصرف فيها على أي نحو آخر، أو فيما يتصل بالدفاع في مواجهة أي مطالبة</li>
        <li> إلى السلطات الرسمية، ويشمل ذلك مسؤولي إنفاذ القانون والمحاكم والسلطات الحكومية والتنظيمية وذلك في أي من الحالتين التاليتين: (أ) إذا كنا نعتقد أن الكشف عن البيانات مطلوب بموجب القانون أو اللوائح واجبة التطبيق أو الإجراءات القانونية (كالاستجابة إلى أمر استدعاء أو غير ذلك من الأوامر القضائية) أو (ب) لحماية حقوقنا والدفاع عنها أو حقوق أو سلامة الغير، ويشمل ذلك الدفاع في مواجهة الدعاوى القانونية.</li>
      </ol>
      <p>عند الكشف عن البيانات الشخصية إلى الغير، سنتخذ التدابير التعاقدية والتقنية والتنظيمية المطلوبة لتوفير الحماية الواجبة لمثل تلك البيانات وفقاً للتشريعات واجبة التطبيق.
        <br/>
        قد يتضمن النقل في الحالات المذكورة سابقاً نقل بياناتك الشخصية إلى بلدان غير بلد إقامتك، ويشمل ذلك النقل إلى بلدان قد تكون قوانينها لحماية البيانات أقل شمولاً من قوانين حماية البيانات في المنطقة الاقتصادية الأوروبية. وعند الضرورة، ستتحقق الخطوط الجوية القطرية من توافر الضمانات الكافية للالتزام باشتراطات نقل البيانات الشخصية بين الدول وفقاً لقوانين حماية البيانات والخصوصية واجبة التطبيق.
      </p>
      <li>كيف نحافظ على أمان بياناتك؟</li>
      <br/>
      <p>طبقت الخطوط الجوية القطرية تدابير لحماية البيانات الشخصية لأننا ملتزمون بضمان تأمين البيانات الشخصية. ومن أجل منع الوصول غير المصرح به للبيانات الشخصية أو الكشف غير المصرح به عنها، نتبع تدابير حماية مادية وتقنية وإدارية لحماية البيانات الشخصية من الإتلاف العرضي أو غير القانوني أو الخسارة العرضية أو التلف أو التحوير أو الكشف عنها أو الوصول إليها على نحو غير مصرح به، فضلاً عن جميع صور المعالجة غير القانونية.
        <br/>
        على الرغم من إننا نبذل جهوداً مناسبة في حدود المعقول لحماية البيانات الشخصية، فإننا لا نستطيع أن نضمن أمن البيانات الشخصية التي تُنقل عبر الإنترنت أو أي اتصال مشابه. وإذا كانت لديك أسباب تدعوك للاشتباه في أن تواصلك معنا لم يعد مؤمناً، فيُرجى التواصل معنا فوراً باستخدام الوسائل الموضحة في قسم
        &nbsp;<b>"كيفية الاتصال بنا"</b>&nbsp;
        في نهاية هذا الإشعار.
      </p>
      <li> استخدام القاصرين للموقع</li>
      <br/>
      <p>هذا الموقع غير موجه لمن تقل أعمارهم عن ثمانية عشر (18) عاماً، ونحن لا نجمع عن علم بيانات شخصية من أشخاص أصغر من 18 عاماً.</p>
      <li> الاحتفاظ بالبيانات</li>
      <br/>
      <p>سنحتفظ بالبيانات الشخصية للمدة اللازمة لتحقيق الغرض المحدد الذي جُمعت من أجله، أو الأغراض القانونية أو التجارية للخطوط الجوية القطرية والموضحة في إشعار الخصوصية هذا، وبما يتفق مع سياساتنا للاحتفاظ بالبيانات، أو وفقاً لما تشترطه القوانين ذات الصلة. وعند حذف البيانات الشخصية، سنتخذ جميع التدابير المعقولة تجارياً والممكنة فنياً حتى يتعذر استرجاع البيانات أو نسخها، وذلك وفقاً للقوانين واجبة التطبيق.</p>
      <li> استخدام القاصرين للموقع</li>
      <br/>
      <p>هذا الموقع غير موجه لمن تقل أعمارهم عن ثمانية عشر (18) عاماً، ونحن لا نجمع عن علم بيانات شخصية من أشخاص أصغر من 18 عاماً.</p>
      <li>حقوقك</li>
      <br/>
      <p>يمكنك ممارسة حقوقك في الوصول إلى بياناتك وطلب تصحيحها أو محوها أو تقييد معالجتها أو طلب الحصول على البيانات في صورة إلكترونية قابلة للنقل أو الاعتراض على معالجتها أو حقوقك فيما يتعلق بوسائل اتخاذ القرار آلياً، بما في ذلك استقراء السمات المميزة، فيما يتعلق بالبيانات الشخصية التي تعالجها الخطوط الجوية القطرية في أي وقت وذلك بإرسال طلب عن طريق بوابة مخصصة
        &nbsp;<a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/en/subject-access-request-form.html"> لطلبات أصحاب البيانات في الوصول إلى بياناتهم</a>&nbsp;
        وذلك على صفحة "اتصل بنا" على موقع qatarairways.com.qa. وقد تكون لك حقوق خصوصية أخرى، بما في ذلك الحقوق المقررة بموجب قانون كاليفورنيا لخصوصية المستهلكين (
        <b>"CCPA"</b>
        )، وذلك استناداً إلى دولة إقامتك.</p>
      <p>وقبل الشروع في تنفيذ طلبك، سنتحقق من هويتك لنضمن أننا نقدم البيانات المطلوبة إلى صاحبها الذي تتعلق به. وإذا فوضت وكيلاً لتقديم الطلب نيابة عنك، فسنطلب منك أو من الوكيل الذي فوضته تقديم نسخة لنا من الإذن المكتوب لتقديم الطلب نيابة عنك.</p>
      <p>ولن نطلب منك دفع أي رسوم للرد على طلبك ما لم يكن ذلك الطلب يمكن أن يعد، في ظاهره، لا أساس له أو مبالغ فيه. وعند طلبك، يمكن أن نقدم لك قائمة بالجهات التي تتعامل مع البيانات الشخصية من الغير، وذلك بموجب قانون حماية المعلومات الشخصية (Personal Information Protection Law) في جمهورية الصين الشعبية.
        <br/>
        إذا كنت تعتقد إننا لم نلتزم بقواعد الخصوصية واجبة التطبيق، فإن لك الحق في تقديم شكوى إلى سلطة حماية البيانات المختصة.
      </p>
      <p>إذا لم تعد ترغب في تلقي نشراتنا البريدية، فيمكنك إلغاء اشتراكك في أي وقت بالضغط على رابط "unsubscribe" (إلغاء الاشتراك) الموجود في أي رسالة بريد إلكتروني تحتوي على نشرتنا الإخبارية. كما يمكنك تقديم طلب إلغاء اشتراك بمراسلة مركز خدمة العملاء بالبريد الإلكتروني أو الاتصال به هاتفياً أو بإرسال طلب إلينا بالبريد على العنوان التالي:</p>
      <p>
        مجموعة الخطوط الجوية القطرية ش.م.ق.م
        <br/>
        برج الخطوط الجوية القطرية رقم 1،
        <br/>
        ص. ب. 22550،
        <br/>
        الدوحة، دولة قطر.
        <br/>
        عناية: فريق الخطوط القطرية للتسويق المباشر - التجارة الإلكترونية
      </p>
      <p>عند تقديم طلب لإلغاء الاشتراك بإرسال رسالة بريد إلكتروني أو الاتصال بنا أو إرسال خطاب، فقد يستغرق الأمر ما يصل إلى 30 يوم عمل لمعالجة طلبك وتحديث أنظمتنا لحذف عنوان بريدك الإلكتروني من قائمتنا البريدية.</p>
      <li>كيفية الاتصال بنا أو تقديم شكوى</li>
      <br/>
      <p>إذا كانت لديك أي أسئلة أو مخاوف بخصوص معالجة بياناتك الشخصية أو إشعار الخصوصية هذا، يُرجى التواصل عبر:</p>
      <p>البريد الإلكتروني:
        &nbsp;<a target="_blank" rel="noreferrer" href="mailto:digitalbrand@qatarairways.com.qa">digitalbrand@qatarairways.com.qa</a></p>
      <p>
        مجموعة الخطوط الجوية القطرية ش.م.ق.م
        <br/>
        برج الخطوط الجوية القطرية رقم 1،
        <br/>
        ص. ب. 22550،
        <br/>
        الدوحة، دولة قطر.
        <br/>
        عناية: إدارة التسويق
      </p>
      <p>يمكنك أيضاً التواصل مع مسؤول حماية البيانات عبر عنوان البريد الإلكتروني هذا
        &nbsp;<a target="_blank" rel="noreferrer" href="mailto:dpo@qatarairways.com.qa">dpo@qatarairways.com.qa</a>
        . ويُرجى العلم أنك قد يحق لك أيضاً تقديم شكوى إلى السلطة الإشرافية المختصة.</p>
      <br/>
    </ol>
  )
}
