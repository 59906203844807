import React from 'react';

const Download = () => {
    return (
        <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.0815 29.3602H4.91872C2.83267 29.3602 1.13965 27.6672 1.13965 25.5811V19.5346C1.13965 19.1114 1.47221 18.7788 1.89546 18.7788C2.31872 18.7788 2.65128 19.1114 2.65128 19.5346V25.5811C2.65128 26.8358 3.66407 27.8486 4.91872 27.8486H26.0815C27.3362 27.8486 28.3489 26.8358 28.3489 25.5811V19.5346C28.3489 19.1114 28.6815 18.7788 29.1048 18.7788C29.528 18.7788 29.8606 19.1114 29.8606 19.5346V25.5811C29.8606 27.6672 28.1676 29.3602 26.0815 29.3602Z"
                fill=""
            />
            <path
                d="M15.5322 0.639344C15.7288 0.639344 15.9253 0.714925 16.0613 0.866088L23.6195 8.42423C23.9067 8.72655 23.9067 9.19516 23.6043 9.49748C23.3171 9.78469 22.8485 9.78469 22.5462 9.49748L15.5171 2.46841L8.48806 9.49748C8.18573 9.78469 7.71713 9.78469 7.4148 9.48237C7.12759 9.19516 7.12759 8.72655 7.4148 8.42423L14.9729 0.866088C15.109 0.730042 15.3055 0.639344 15.502 0.639344L15.5322 0.639344Z"
                fill=""
            />
            <path
                d="M15.5323 0.639364C15.9555 0.639364 16.2881 0.971922 16.2881 1.39518L16.2881 19.5347C16.2881 19.958 15.9555 20.2905 15.5323 20.2905C15.109 20.2905 14.7765 19.958 14.7765 19.5347L14.7765 1.39518C14.7765 0.971922 15.109 0.639364 15.5323 0.639364Z"
                fill=""
            />
        </svg>
    );
};
export default Download;
