import styles from './styles.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import utils from '../../helpers/utils'

interface SkinTypeSelectorProps {
  pictureSkinType: number;
  setPictureSkinType: (item: number) => void;
}

export default function SkinTypeSelector({ pictureSkinType, setPictureSkinType}: SkinTypeSelectorProps) {
  const { t, } = useTranslation();
  return (
    <div className={styles.pictureSkinTypeBox} dir="ltr">
      <p>{t('select_skin')}</p>
      <div className={styles.pictureSkinTypes}>
        {
          utils.skinTones.map((color, index) => (
            <div
              key={`picture-skin-type-${index}`}
              className={clsx(styles.pictureSkinType, {
                [styles.selected]: pictureSkinType === (index + 1),
              })}
              style={{ backgroundColor: color }}
              onClick={() => setPictureSkinType(index + 1)}
            />
          ))
        }
      </div>
    </div>
  )
}
