export default function zhCNText() {
  return (
    <div>
      <p>请在访问和使用本网络应用程序及其任何服务、功能、材料或内容（“<b>下称“微网站</b>”）之前阅读本使用条款。本微网站由卡塔尔航空集团Q.C.S.C拥有和运营，卡塔尔航空集团Q.C.S.C是一家卡塔尔股份公司，位于Qatar Airways Tower 1, Airport Road, State of Qatar（卡塔尔国）</p>
      <p>访问、使用、浏览、传输、缓存或存储本微网站（ai.flyqatar.com）即表明您同意受本条款与条件（“使用条款”）的约束，以获取下述对微网站的访问权，且您保证您已达到法定成年年龄并具有签署本协议的法律行为能力，并在使用本微网站时遵守本使用条款的所有条款。您同意对您在本网站上的所有使用行为（以及其他人使用您账户的行为）承担经济责任。</p>
      <p>“您”是指访问本微网站的人（包括以他人名义访问本网站的人）。“我们”和其他相关词汇指卡塔尔航空。若您不同意本使用条款、以下链接的任何相关文档或在使用本微网站过程中获知的其他限制，则您无权且同意不使用或访问本微网站。</p>
      <p>本使用条款约束您对微网站以及微网站上提供的所有应用程序、软件和服务（统称“服务”）的使用，除非此类服务另有单独协议。使用某些服务和微网站上向您提供的其他项目可能适用特定条件（“服务协议”）。本使用条款中未另行定义的词汇应具有本使用条款末尾所附“定义”部分所赋予的含义。</p>
      <h3>使用条款</h3>
      <h3>您对微网站的使用</h3>
      <p>在您始终遵守本使用条款的前提下，您可以选择通过照片将您的图像和形象（“<b>您的内容</b>”）提交到微网站，以纳入微网站上提供的影片视频（“<b>影片</b>”）（“<b>目的</b>”）。包含您的内容的影片在本使用条款中称为“修改后影片”。</p>
      <p>将您的内容提交至微网站以纳入影片即表明您：</p>
      <ol className="alpha">
        <li>授予我们全球范围内非排他、免版税、可转让、永久、不可撤销、可转授权的权利，以基于目的使用、复制、修改您的内容和创作其衍生作品；</li>
        <br/>
        <li>
          向我们声明、保证并承诺：
          <ol className="roman">
            <li>您有能力为上述目的授予我们对您的内容的权利；</li>
            <li>您拥有并将继续拥有授予我们为上述目的使用您的内容所需的所有权利、所有权和权益；</li>
            <li>我们依据本使用条款的规定和其意图或允许的方式使用您的内容不会侵犯任何第三方的知识产权或违反任何适用法律；</li>
            <li>卡塔尔航空或任何按卡塔尔航空指示或许可行事的人员（包括卡塔尔航空集团成员）均无须因根据本使用条款的规定和其意图或允许的方式使用您的内容而向任何第三方支付任何许可费或其他费用。</li>
          </ol>
        </li>
      </ol>
      <br/>
      <p>一旦您得知或怀疑您的内容侵犯或涉嫌侵犯任何第三方的知识产权，您应尽快通知我们，并在我们为维护知识产权采取的任何法律行动中与我们充分合作。</p>
      <p>在您始终遵守本使用条款的前提下，我们授予您全球范围内有限、基于个人、可撤销、免版税、不可转让、不可转授权的权利，仅供您出于个人用途（不可用于任何商业目的）使用、下载、复制、上传、传输、翻译和修改修改后影片。</p>
      <p>任何未在本使用条款中明确授予您的权利均归我们所有。</p>
      <h3>许可与所有权 - 卡塔尔航空</h3>
      <p>与微网站及其内容（包括影片和修改后影片，下称“内容”）相关的所有知识产权均为卡塔尔航空集团Q.C.S.C或第三方的专有财产。我们微网站的内容受适用版权法及其他法律的保护。</p>
      <p>微网站的元素同样受商业秘密法、不正当竞争法及其他法律的保护，且不可整体或部分复制或模仿。微网站上显示的所有定制图形、图标及其他项目均为卡塔尔航空、其附属公司或其他授予卡塔尔航空相关使用权的实体的商标或服务标志（“标志”）。未经卡塔尔航空公司明确书面同意，不可以任何方式使用或干扰上述标志。</p>
      <p>除非本使用条款另有明确授权，未经卡塔尔航空或相关第三方的事先书面许可，您不可以任何方式复制、重制、修改、出租、出借、出售、创作衍生作品、上传、传输或分发微网站的知识产权。除本条款中明确规定外，卡塔尔航空不授予您任何明示或默示的卡塔尔航空或任何第三方的知识产权权利。</p>
      <p>在不影响本使用条款其他条款的前提下，卡塔尔航空授予您有限、基于个人、不可转让、不可转授权、可撤销的许可，以便您（a）仅以卡塔尔航空提供的方式访问和使用微网站、内容和服务，及（b）仅以卡塔尔航空明确许可的方式访问和使用微网站内提供的卡塔尔航空计算机和网络服务（“卡塔尔航空系统”）。</p>
      <p>除本使用条款中授予的有限许可外，卡塔尔航空允许您访问微网站不代表向您转让任何与卡塔尔航空系统、卡塔尔航空系统提供的信息或数据（“信息”）、内容、服务、微网站或任何其他卡塔尔航空财产相关的权益。</p>
      <p>除法律要求或本条款中明确规定的情况外，未经卡塔尔航空事先书面同意，不可对任何内容和/或信息进行逆向工程、修改、重制、再版、翻译为任何语言或计算机语言、以任何形式或方式重新传输、转售或再分发。在不影响上述条款的前提下，您同意不：（a）未经卡塔尔航空事先书面许可，复制、展示、修改、重制或以其他方式传输任何材料至任何第三方；（b）干扰或破坏连接至网站的网络；（c）使用或尝试使用任何干扰网站正常运行或通过网站提供的任何交易的设备、软件或程序；（d）传输包含计算机病毒、损坏数据、蠕虫、“特洛伊木马”或其他可能删除数据或程序，或导致网站或任何设备或系统无法运行或无法按照设计用途使用的指示或设计的文件、数据或其他材料；（e）向网站或通过网站发送任何违反当地、州、联邦或国际法律的通信；（f）向网站或通过网站发送任何包含诽谤、中伤、侮辱或淫秽内容的通信；（g）向网站或通过网站发送任何侵犯第三方权利的通信。 </p>
      <p>此外，对于内容，您在展示、使用、传输、复制或分享修改后影片及/或整合了修改后影片的其他内容中时不可：</p>
      <ol className="alpha">
        <li>冒充我们、我们的任何附属公司、卡塔尔航空集团的任何成员，或者我们或我们的附属公司的代理人，或以其他方式虚假陈述您的身份或附属关系；</li>
        <li>伪造标头或以其他方式篡改标识符（包括URL），从而误导或可能误导第三方，使其无法识别您的身份或与我们的附属关系；</li>
        <li>参与任何违反信托关系、适用地方、州、国家或国际法律或任何具有法律效力的规定的行为；</li>
        <li>以任何不实、有害、淫秽、诽谤、种族歧视或其他侵犯性的方式传输、滥发或上传、发布或以其他方式传播修改后影片；</li>
        <li>作出任何有损我们或卡塔尔航空集团利益的行为或不作为，由我们全权酌情决定；</li>
        <li>删除修改后影片中的任何版权声明；</li>
        <li>以任何侵犯、盗用或损害他人知识产权或其他权利，或违反适用法律的方式使用修改后影片；</li>
        <li>访问全部或部分内容以用于开发与内容或其任何方面竞争的产品和/或服务；或</li>
        <li>基于商业目的利用、销售、授权或分发内容的任何部分。</li>
      </ol>
      <br/>
      <p>此外，您还应遵守我们可能不时发布的有关修改后影片的其他附加规则。</p>
      <h3>版权和商标信息</h3>
      <p>本微网站上所有材料、基础软件代码或软件数据均受版权、商标和/或其他知识产权的保护。这些材料归卡塔尔航空集团公司 Q.C.S.C所有，经所有者的允许或根据法律授权使用。受保护的材料包括但不限于文本、商标、源代码、数据库、徽标、照片、图像、视频剪辑、音频剪辑、设计、商业外观，以及本微网站、移动网站和移动设备中包含的其他材料、应用程序。保留全球范围的所有权利。</p>
      <p>除非本使用条款明确允许，您不可下载、打印或重制内容的任何部分作个人非商业用途和享受，并严禁以任何其他方式使用本微网站、移动网站和移动应用程序上的任何内容（包括但不限于照片、文章、新闻稿、软件、评论、表格或图表、视频片段、徽标等）。例如，此类被禁止的使用行为可能包括提取、再分发、再版、修改、传播、销售或以其他方式向第三方提供本微网站上的任何信息或材料（无论以何种形式）。除此限制外，您还同意遵守通过本微网站访问的任何材料中包含的所有版权声明、信息或限制。未经授权使用本微网站、移动网站和移动应用程序或本微网站上包含的任何信息或材料是非法行为，并因此可能导致损害索赔和其他后果，并可能构成刑事犯罪。</p>
      <h3>未经授权的行为或使用</h3>
      <p>您同意出于个人目的使用本微网站，本微网站仅供您查看和购买我们的产品和服务，而无其他目的。特别是，您同意不会进行任何未经授权的、虚假的或欺诈性的预订；您不会试图干扰本微网站的运行，也不会更改或破坏任何数据或软件。</p>
      <p>您有责任对您的微网站访问信息（例如用户名、密码和个人识别码）和预订数据（例如预订参考号和您的旅行行程）进行保密。</p>
      <p>您可以在以下情况下通过向家人、朋友或同事提供微网站访问信息和/或预订数据，以允许其以您的名义访问微网站：</p>
      <ol className="alpha">
        <li>您已经获得任何其他相关人员的同意，这些人员的预订数据或个人数据可通过您的数据访问（例如团体预订）；且</li>
        <li>无论访问是否由您授权，您对使用您提供的微网站访问信息和/或预订数据访问微网站的人员的作为和不作为承担全部责任。</li>
      </ol>
      <br/>
      <p>您不可允许任何商业实体或向公众提供服务的其他组织访问或使用本微网站，或本微网站上的任何材料，或从本微网站获得的任何材料。</p>
      <h3>您的承诺</h3>
      <p>除使用条款中规定的其他限制，您还同意：</p>
      <ol className="alpha">
        <li>您不可掩饰通过本微网站传输的信息的来源。</li>
        <li>您不会以卡塔尔航空明确禁止的方式使用或访问通过微网站提供的任何服务、信息、应用程序或软件。</li>
        <li>您不会将向微网上输入或上传任何包含病毒、特洛伊木马、蠕虫、定时炸弹，或其他旨在破坏、干扰、拦截或控制任何系统、微网站或信息或侵犯他人知识产权的计算机程序例程。</li>
        <li>微网站的某些区域仅限于卡塔尔航空的客户使用。</li>
        <li>您不可通过任何卡塔尔航空认为会对卡塔尔航空系统、服务或微网站的性能或功能产生不利影响，或会干扰授权方访问卡塔尔航空系统、服务或微网站的方式使用或访问微网站或卡塔尔航空系统或服务。</li>
        <li>未经卡塔尔航空的明确书面同意，您不可使用框架或利用框架技术嵌套内容或信息的任何部分或任何方面。</li>
      </ol>
      <br/>
      <p>您同意服从并遵守本使用条款以及本微网站中列出的所有指示和程序。您了解：如果不遵守所有指示和程序，可能会导致您购买不需要的商品或服务的义务；收回您的产品或服务；拒绝访问本微网站的产品或服务；并可能对您提起法律诉讼。</p>
      <p>您认可并同意您仍对以下情况负责并承担责任：</p>
      <ol className="alpha">
        <li>评估并承担与使用微网站及其内容相关的所有风险；</li>
        <li>确保对您的内容和/或您在访问和使用微网站时使用的系统进行适当的保护和备份；</li>
        <li><b>您的微网站和内容的使用，无论访问和使用是否遵守或违反本使用条款，包括一并使用的任何移动应用程序或第三方软件。</b></li>
      </ol>
      <br/>
      <h3>免责声明和责任限制</h3>
      <ol className="alpha">
        <li> 尽管产品信息及所有其他微网站、内容、移动网站和移动应用程序的材料均是本着诚信原则提供的，但使用本微网站、内容、移动网站和移动应用程序即表明您同意并承认卡塔尔航空不对产品信息及本微网站、其内容、移动网站和移动应用程序上的任何其他材料的准确性作出任何表示、保证或担保。我们不表示或保证您使用微网站时不会发生中断或错误，也不表示或保证微网站或其内容不含有害代码。此外，您同意产品信息及微网站、其内容、移动网站和移动应用程序的材料不构成对您的任何形式的意见或建议。</li>
        <li>卡塔尔航空免除与您使用本微网站、其内容、移动网站和移动应用程序，以及本微网站、其内容、移动网站和移动应用程序所包含的信息、产品和服务有关的一切默示保证责任。在法律允许的最大范围内，对本微网站、其内容、移动网站和移动应用程序上的所有信息均不提供任何担保（无论明示、法律暗示或其他形式）或任何形式的默示条款，包括但不限于任何品质满意、适用于特定用途或不侵犯第三方权利的任何默示保证或默示条款。</li>
        <li>使用本微网站、其内容、移动网站和移动应用程序，即表示您同意卡塔尔航空对因使用（或不使用）本微网站、其内容、移动网站和移动应用程序包含的信息、产品和服务，或者您通过本微网站、其内容、移动网站和移动应用程序的链接访问其他材料而导致的任何直接、间接、后续或任何其他损失不承担任何责任。本使用条款中包含的排除和限制在法律允许的最大范围内适用。</li>
        <li>卡塔尔航空不保证本微网站、其内容、移动网站和移动应用程序的使用与网站访问者可能使用的所有硬件和软件兼容。</li>
        <li>除f项和g项另有规定外，对于因使用本微网站、其内容、移动网站和移动应用程序或因使用、访问、下载或依赖本微网站、其内容、移动网站和移动应用程序中包含的任何信息或其他材料（包括但不限于由于任何计算机病毒）而直接或间接导致的任何人身伤害、死亡、损害，或直接、间接或后续损失（三项均包括但不限于纯经济损失、利润损失、业务损失、商誉受损以及类似损失），卡塔尔航空对您不承担任何基于合同、侵权（包含疏忽）、违反法定义务、返还义务或其他的责任。</li>
        <li>本使用条款不排除卡塔尔航空因卡塔尔航空的过失、欺诈或卡塔尔航空无法合法排除或试图排除其责任的任何事由而造成的人身伤害或死亡而对您应承担的责任（如有）。</li>
        <li>除f项另有规定外，对于因卡塔尔航空提供本微网站、其内容、移动网站和移动应用程序及任何相关服务而直接或间接导致的任何事项或系列事项，卡塔尔航空的全部责任应限于每一事项或系列事项合计不超过1,000美元。</li>
        <li>若卡塔尔航空和/或其许可人因您违反任何使用条款而遭受损失、损害、索赔和费用（包括法律费用），您应向卡塔尔航空及其许可人承担责任并保证全额赔偿卡塔尔航空和/或其许可人。</li>
      </ol>
      <br/>
      <h3>隐私权和Cookies</h3>
      <p>卡塔尔航空承诺保护您的隐私，遵守适用的数据保护和隐私法律，保护您的个人数据。我们所称的“个人数据”是指任何与已识别或可识别的自然人关联的信息。个人数据将根据我们的《隐私声明》 <a target="_blank" rel="noreferrer" href="/privacypolicy?selLang=zh-cn">【可在此查阅】</a>进行处理。</p>
      <p>Cookie是一种小型信息文件，用于保存和检索您访问本微网站的情况，例如，您如何进入我们的网站、如何浏览网站以及对什么信息感兴趣。请阅读我们的《Cookie政策》 <a target="_blank" rel="noreferrer" href="https://www.qatarairways.com/zh-cn/legal/cookie-policy.html">【可在此查阅】</a>，进一步了解我们如何使用cookie。</p>
      <h3>链接</h3>
      <p>未经我们书面同意，严禁链接到本微网站，且该同意可以随时撤消。链接到http://www.qatarairways.com或其中任何页面的微网站或其他设备均禁止：</p>
      <ol className="alpha">
        <li>复制内容；</li>
        <li>在内容周围使用浏览器或边框环境；</li>
        <li>以任何方式暗示卡塔尔航空或其任何附属公司认可其公司或产品；</li>
        <li>虚假陈述任何事实，包括与卡塔尔航空或其任何附属公司的关系；</li>
        <li>提供有关卡塔尔航空产品或服务的虚假信息；以及</li>
        <li>未经卡塔尔航空的明确书面许可，使用卡塔尔航空或其任何附属公司的徽标或标志。</li>
      </ol>
      <br/>
      <p>微网站可能包含指向第三方微网站和资源的链接（统称为“链接站点”）。提供这些链接站点仅是为了您的方便，而不是卡塔尔航空对此类链接站点上的内容的认可。卡塔尔航空对在链接站点上提供的任何内容、软件、服务或应用程序的正确性、准确性、性能或质量不做任何表示或保证。卡塔尔航空对链接站点或此类站点的内容或活动的可用性不负责任。如果您决定访问链接站点，其风险由您承担。此外，您对链接站点的使用受适用政策以及使用条款与条件（包括但不限于链接站点的隐私和Cookie政策）的约束。</p>
      <h3>条款修改</h3>
      <p>卡塔尔航空可随时更新、修改、更改或删除本微网站上提供的本使用条款和/或其他信息服务、产品和内容，而无需通知您。使用本微网站即表示您已同意接受其最新版本的约束。</p>
      <h3>其他条款</h3>
      <p>您与卡塔尔航空之间另行约定的除外，本使用条款将取代以前的所有合同、安排、陈述（欺诈性的虚假陈述除外）、法律隐含的条款以及双方之间因习俗或者书面、口头达成的与本主题相关的其他谅解。本使用条款以英语书就。特此声明，双方已要求本使用条款以及与之相关的所有文档（无论是现在还是将来）都仅以英语写就。</p>
      <h3>可分割性和放弃</h3>
      <p>法院、裁判庭、行政机关或主管司法机关发现本使用条款的任何部分是非法、无效或不可执行的，则该条款将在必要的范围内从本使用条款中分离出来，而尽可能不修改本使用条款任何其他部分，并且该无效不会影响经修订的使用条款的其他条款，这些条款仍将完全有效。</p>
      <p>本条款与条件的任何条款、规定或条件均不得放弃，除非该放弃以书面形式证明并由放弃的一方签署。本使用条款的任何条款、规定或条件均不得放弃，除非该放弃由放弃的一方书面证明并签署。</p>
      <h3>准据法律和管辖</h3>
      <p>本微网站的使用条款应根据卡塔尔国法律进行规制和解释。卡塔尔法院应具有非专属管辖权，以裁定由此产生的任何争议。在法律允许的最大范围内，您同意卡塔尔航空保留在其认为适当的任何国家对您提起诉讼的权利。</p>
      <h3>终止</h3>
      <p>您同意卡塔尔航空全权酌情决定随时或以任何理由或无理由，终止或暂停您对微网站以及卡塔尔航空系统、信息、服务和内容的使用，即使是在继续允许他人访问和使用的情况下。暂停或终止后，您必须立即（a）停止使用微网站，并且（b）销毁您对内容的任何部分所作的任何副本。在终止、中止、暂停后访问微网站和卡塔尔航空系统、信息或服务，即构成侵入行为。此外，您同意卡塔尔航空对终止或暂停您或任何第三方访问微网站和卡塔尔航空系统、信息和/或服务不承担任何责任。</p>
      <h3>联系我们</h3>
      <p>如果您对我们的微网站或我们的使用条款有任何疑问，请联系：</p>
      <p>卡塔尔航空集团 Q.C.S.C<br/>
        Qatar Airways Tower<br/>
        P.O. Box: 22550<br/>
        Doha, State of Qatar<br/>
        电邮：<a target="_blank" rel="noreferrer" href="mailto:qatarairways.com/contactus">qatarairways.com/contactus</a>
      </p>
      <br/>
      <table>
        <tbody>
        <tr>
          <td colSpan={2}>定义</td>
        </tr>
        <tr>
          <td>附属公司</td>
          <td>指直接或间接控制合同方、受合同方控制或与合同方受共同控制的任何实体；</td>
        </tr>
        <tr>
          <td>有害代码 </td>
          <td>指：(a)任何病毒、特洛伊木马、蠕虫、间谍软件、后门、陷阱门、叉式炸弹、逻辑炸弹、恶意软件或拒绝服务；以及(b)任何其他意在或设计为有害、破坏性或干扰性，以导致故障、错误、缺陷、拒绝服务或服务质量下降、或数据丢失、非授权访问、修改、披露或损坏的软件程序。</td>
        </tr>
        <tr>
          <td>知识产权 </td>
          <td>指任何种类的所有工业产权和知识产权，包括但不限于版权（包括计算机软件权）、商标、服务标志标、设计、专利、商业秘密、半导体或电路布局权、贸易、商业、域名或公司名称、保密信息权、专有技术或其他专有权利（无论这些权利是否注册，包括任何注册申请），以及在世界任何地方存在的与上述任何权利性质类似或具有同等或类似效力的所有权利或保护形式。</td>
        </tr>
        <tr>
          <td>卡塔尔航空集团</td>
          <td>指卡塔尔航空集团Q.C.S.C.及其各子公司、附属公司、管理实体、许可受让人和继承人。（中文为译本，请以英文版为准。）</td>
        </tr>
        </tbody>
      </table>
      <br/>
    </div>
  )
}
