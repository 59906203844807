import { FaceSwap } from '../bdreams/FaceSwap';

export enum FaceSwapItemStatus {
    OK = 'Ok',
    NO_IMAGE = 'NoImage',
    ERROR = 'Error',
}

export interface FaceSwapItem extends FaceSwap {
    status: FaceSwapItemStatus;
}
